import React, {useEffect, useState} from 'react';
import {CalendarResponse, ServiceReport} from '../utils/api';
import {Circle as ServiceCircle} from './SVGs/ServiceCircle';
import '../styles/ServiceView.css';
import {AnimatedWindmill} from './SVGs/Windmill';
import {Service} from './Service';
import {NavWheel} from './SVGs/NavWheel';
import {KeySymbolsLegend} from './SVGs/KeySymbols';
import {animated, useSpring, useSprings, useTransition} from 'react-spring';
import {Video} from './Video';
import {ReactComponent as PopupText} from '../assets/PopupText.svg';
import {ReactComponent as Hand} from '../assets/Hand3.svg';
import {useIdleTimer} from "react-idle-timer";
import {getInitDay} from "../utils/utils";
import * as timers from "../constants/intervals"
import { n, moveRange, widthOutput, widthRange } from "../constants/animationData"

const logo = require('../assets/logo.png');

const AnimatedPopupText = animated(PopupText)
const AnimatedHand = animated(Hand)

export enum SView {
  Home,
  Intro,
  Details,
  History,
}

interface ServiceViewProps {
  services: ServiceReport[];
  changeDay: (offset: number) => void;
  changeCalendarDate: (newDate: Date | undefined) => void;
  currentDay: Date;
  calendarColoring: CalendarResponse;
}

export function ServiceView(props: ServiceViewProps) {
  const {changeCalendarDate, changeDay, currentDay, services, calendarColoring} = props;
  const servicesCount = services.length;
  const centerElement = Math.trunc(servicesCount / 2);
  const [currentView, setCurrentView] = useState(SView.Home);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [currentServiceIndex, setCurrentServiceIndex] = useState(centerElement);

  // state for video popover
  const [videoOpen, setVideoOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  // state for idle animations
  const [goHomeCountdown, setGoHomeCountdown] = useState(10);
  const [tourStarted, setTourStarted] = useState(false);
  const [monthSwitched, setMonthSwitched] = useState(false);
  const [keyOpen, setKeyOpen] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);

  // timers
  const [blinkTimer, setBlinkTimer] = useState<any>(null);
  const [popupTimer, setPopupTimer] = useState<any>(null);
  const [tourTimer, setTourTimer] = useState<any>(null);
  const [goHomeTimer, setGoHomeTimer] = useState<any>(null);

  const isCenter = (index: number) => {
    return (servicesCount + index + currentOffset) % servicesCount === centerElement
  }

  /// SPRINGS
  // service movement
  const [springs, setSprings] = useSprings(services.length,
    index => ({
      opacity: 1,
      left: `${index * 20}vw`,
      marginTop: '0vh', width: `20%`,
      //config: {duration: 1500}
    }))

  // spin logo in nav
  const [spinLogoProps, setSpinLogoProps] = useSpring(() => ({transform: 'rotate(0deg)'}));
  const [rowOffsetProps, setRowOffsetProps] = useSpring(() => ({
    from: {topOffset: 12},
    topOffset: 12
  }))
  const {topOffset} = rowOffsetProps

  // center element info
  const [opacityProps, setOpacityProps] = useSpring(() => ({opacity: 0}))
  // center element blink
  const [blinkState, toggleBlinkState] = useState(false)
  // @ts-ignore
  const {b} = useSpring({from: {b: 0}, b: blinkState ? 1 : 0, config: {duration: timers.blinkDuration / 4}})

  // popup
  const [popupState, togglePopupState] = useState(false)
  const {x} = useSpring({
    from: {x: 0, opacity: 0},
    x: popupState ? 1 : 0,
    opacity: 1,
    config: {duration: timers.popupDuration}
  })

  //tour
  const [tourState, toggleTourState] = useState(false)
  // @ts-ignore
  const {p} = useSpring({from: {p: 0}, p: tourState ? 1 : 0, config: {duration: timers.tourDuration}})

  const transitions = useTransition(tourStarted, null, {
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0},
  });

  const countdownTransition = useTransition(showCountdown, null, {
    from: {opacity: 0},
    enter: {opacity: 0.9},
    leave: {opacity: 0},
  });

  // handle service offsets and animations
  const handleServiceMovement = (changeToView?: SView, offset?: number) => {
    if (changeToView === SView.Home) {
      // @ts-ignore
      setSprings(index => ({
          zIndex: 101,
          opacity: 1,
          width: '20vw',
          left: `${((servicesCount + index + currentOffset) % servicesCount) * 20}%`,
          marginTop: '0vh',
        })
      )
    } else if (offset !== undefined) {
      // @ts-ignore
      setSprings(index => ({
          zIndex: index + offset === servicesCount - 1 ? 1 : index + offset + servicesCount,
          opacity: index + offset === 2 ? 1 : 0.5,
          width: index + offset === 2 ? '28vw' : '18vw',
          left: `${((servicesCount + index + offset) % servicesCount) * 18 + (((servicesCount + index + offset) % servicesCount) > 2 ? 10 : 0)}%`,
          marginTop: index + offset === 2 ? '-1.5vh' : '3vh',
        })
      )
    } else {
      // @ts-ignore
      setSprings(index => ({
          zIndex: index + currentOffset,
          opacity: index + currentOffset === 2 ? 1 : 0.5,
          width: index + currentOffset === 2 ? '28vw' : '18vw',
          left: `${((servicesCount + index + currentOffset) % servicesCount) * 18 + (((servicesCount + index + currentOffset) % servicesCount) > 2 ? 10 : 0)}%`,
          marginTop: index + currentOffset === 2 ? '-1.5vh' : '3vh',
        })
      )
    }
  }

  // trigger view change animations
  const handleViewChange = () => {
    switch (currentView) {
      case SView.Intro:
        setSpinLogoProps({transform: 'rotate(-26)'});
        setRowOffsetProps({topOffset: 0})
        setOpacityProps({opacity: 1, delay: 500})
        handleServiceMovement(SView.Intro)
        break;
      case SView.Details:
        setSpinLogoProps({transform: 'rotate(8)'});
        setRowOffsetProps({topOffset: 0})
        setOpacityProps({opacity: 1, delay: 500})
        handleServiceMovement(SView.Details)
        break;
      case SView.History:
        setSpinLogoProps({transform: 'rotate(56)'});
        setRowOffsetProps({topOffset: 0})
        setOpacityProps({opacity: 1, delay: 500})
        handleServiceMovement(SView.History)
        break;
      default:
        setSpinLogoProps({transform: 'rotate(0)'});
        setRowOffsetProps({topOffset: 12, delay: 700})
        setOpacityProps({opacity: 0})
        handleServiceMovement(SView.Home)
    }
  }

  // timing setup
  // actions on idle
  // @ts-ignore
  const handleOnIdle = event => {
    console.log('user is idle', event)

    // reset the screen  to home view after long idle
    currentView !== SView.Home && setTimeout(() => {
      if (isIdle()) {
        setShowCountdown(true)
        const timer = setInterval(() => {
          setGoHomeCountdown(goHomeCountdown => goHomeCountdown - 1)
        }, timers.goHomeCountdownTime);
        setGoHomeTimer(timer)
      }
    }, timers.goHomeTimeout);

    // circle blink
    setTimeout(() => {
      if (isIdle()) {
        const timer = setInterval(() => {
          toggleBlinkState(blinkState => !blinkState)
        }, timers.blinkInterval);
        setBlinkTimer(timer)
      }
    }, timers.blinkDelay);

    // popup
    setTimeout(() => {
      if (isIdle()) {
        const timer = setInterval(() => {
          togglePopupState(popupState => !popupState)
        }, timers.popupInterval);
        setPopupTimer(timer)
      }
    }, timers.popupDelay);

    //tour
    setTimeout(() => {
      if (isIdle()) {
        const timer = setInterval(() => {
          toggleTourState(true)
        }, timers.tourInterval);
        setTourTimer(timer)
      }
    }, timers.tourDelay);
  }

  // reset to default home view
  const goHome = () => {
    setCurrentView(SView.Home);
    changeCalendarDate(getInitDay());
    setGoHomeCountdown(10)
    setCurrentOffset(0)
    setCurrentServiceIndex(centerElement)
    setShowCountdown(false)
    setKeyOpen(false)
  }

  // track go home countdown
  useEffect(() => {
    if (goHomeCountdown === 0) {
      goHomeTimer && clearInterval(goHomeTimer)
      goHome()
    }
  }, [goHomeCountdown, goHomeTimer]);

  //actions on active, reset all timers and states
  // @ts-ignore
  const handleOnActive = event => {
    console.log('user is active', event)
    setTourStarted(false)
    clearInterval(blinkTimer)
    clearInterval(popupTimer)
    clearInterval(tourTimer)
    clearInterval(goHomeTimer)

    setBlinkTimer(null)
    setPopupTimer(null)
    setTourTimer(null)
    setGoHomeTimer(null)
    setGoHomeCountdown(10)
    setShowCountdown(false)
  }

  // idle timer setup
  const {isIdle} = useIdleTimer({
    timeout: timers.idleTime,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500
  })

  /// EFFECTS
  // tour
  useEffect(() => {
    if (tourState) {
      setTourStarted(true)
      setTimeout(() => isIdle() && setCurrentView(SView.Intro), timers.tourDuration * 2.2/n);

      setTimeout(() => isIdle() && setCurrentView(SView.Details), timers.tourDuration * 4.2/n);

      setTimeout(() => isIdle() && setCurrentView(SView.History), timers.tourDuration * 6.2/n);

      setTimeout(() => isIdle() && setMonthSwitched(true), timers.tourDuration * 8.2/n);

      setTimeout(() => isIdle() && changeDay(-30), timers.tourDuration * 10.2/n);

      setTimeout(() => isIdle() && changeDay(-3), timers.tourDuration * 12.2/n);

      setTimeout(() => {
        isIdle() && handleServiceOffsetChange((currentServiceIndex + 1) % servicesCount, true )
      }, timers.tourDuration * 14.2/n);

      setTimeout(() => {
        isIdle() && handleServiceOffsetChange(currentServiceIndex, true)
      }, timers.tourDuration * 16.2/n);

      setTimeout(() => {
        isIdle() && setKeyOpen(true)
      }, timers.tourDuration * 18.2/n);

      setTimeout(() => {
        if (isIdle()) {
          setCurrentView(SView.Home)
          changeDay(33)
        }
      }, timers.tourDuration * 20.2/n);

      setTimeout(() => {
        toggleTourState(false)
        setTourStarted(false)
        setMonthSwitched(false)
        setKeyOpen(false)
      }, timers.tourDuration);
    }
  }, [tourState]);

  // trigger view change
  useEffect(handleViewChange, [currentView]);

  // video view control
  const handleVideoOpen = (url: string) => {
    setVideoUrl(url);
    setVideoOpen(true);
  };
  const handleVideoClose = () => {
    setVideoOpen(false);
    setVideoUrl(null);
  };

  // handle change of center service
  const handleServiceOffsetChange = (index: number, dontSwitchView?: boolean) => {
    // switch current element
    setCurrentServiceIndex(index)
    setCurrentOffset(centerElement - index)
    if (currentView === SView.Home && !dontSwitchView) {
      setCurrentView(SView.Intro);
    }
    handleServiceMovement(undefined, centerElement - index)
  };

  // switch page callback for navwheel
  const handleSwitchPage = (toPage: SView) => {
    setCurrentView(toPage);
  };

  return (
    <div className="fullscreen-view">

      {/* service circles */}
      <animated.div key='service-view' className='service-view'
                    style={{paddingTop: topOffset.interpolate(topOffset => `${topOffset}vh`)}}>
        {
          springs.map((props, index) => {
            return (
                <animated.div
                  key={index}
                  style={props}
                  className='card'
                  onClick={() => handleServiceOffsetChange(index)}
                >
                  <animated.div
                    className='blink-card'
                    style={{
                      width: isCenter(index) && !tourStarted && isIdle() && currentView === SView.Home ? b.interpolate({
                        range: [0, 0.25, 0.50, 0.75, 1],
                        output: [100, 106, 98, 106, 100]
                      }).interpolate(b => `${b}%`) : '100%',
                      marginLeft: isCenter(index) && !tourStarted && isIdle() && currentView === SView.Home ? b.interpolate({
                        range: [0, 0.25, 0.50, 0.75, 1],
                        output: [0, -3, 1, -3, 0]
                      }).interpolate(b => `${b}%`) : '0%',
                    }}
                  >
                    <ServiceCircle
                      label={services[index].label}
                      reports={services[index].coloring.reports}
                      date={currentDay}
                      changeDay={changeDay}
                      inactive={calendarColoring[services[index].label.toUpperCase()] === undefined}
                    />
                  </animated.div>
                </animated.div>
            )
          })
        }
      </animated.div>

      {/* service details */}
      {
        <animated.div className="center-element__container" style={opacityProps}>
          <div className="vertical-divider"/>
          <div className="center-element__content">
            <Service
              serviceReport={services[currentServiceIndex]}
              changeDay={changeDay}
              currentView={currentView}
              changeCalendarDate={changeCalendarDate}
              currentDay={currentDay}
              calendarColoring={calendarColoring}
              openVideo={handleVideoOpen}
              monthSwitched={monthSwitched}
            />
          </div>
          <div className="vertical-divider"/>
        </animated.div>
      }

      {/* animated windmills*/}
      {currentView === SView.Home && (
        <div className="background" onClick={() => currentView === SView.Home && setCurrentView(SView.Intro)}>
          <AnimatedWindmill/>
        </div>
      )}

      {/* navigation */}
      <div className="navWheel">
        <NavWheel switchPage={handleSwitchPage} activePage={currentView} tourStarted={tourStarted}/>
      </div>
      <animated.img
        alt="logo"
        className="navWheel__logo"
        src={String(logo)}
        onClick={() => {
          setCurrentView(SView.Home);
        }}
        style={spinLogoProps}
      />
      <div className='navWheel__home-label'
           onClick={() => {
             setCurrentView(SView.Home);
           }}
      >
        Home
      </div>

      {/* legend */}
      <KeySymbolsLegend open={keyOpen}/>

      {/* countdown popup */}
      {countdownTransition.map(({item, key, props}) => {
        return (currentView !== SView.Home && isIdle() && item &&
            <animated.div key="countdown-transition-popup" className='countdown-popup' style={props}>
                <div>
                    Switching to home screen IN {goHomeCountdown} SECONDS
                </div>
            </animated.div>
        )
      })}

      {/* "tap on elements" popup */}
      {transitions.map(({item, key, props}) => {
        return (currentView === SView.Home && isIdle() && !item &&
            <animated.div key="tap-popup" className='absolute' style={props}>
                <AnimatedPopupText
                    className='popup-text'
                    style={{
                      opacity: x
                        .interpolate({
                          range: [0, 0.05, 0.1, 0.15, 0.2, 0.95, 1],
                          output: [0, 0.8, 0.5, 0.9, 1, 1, 0]
                        })
                        .interpolate(x => `${x}`)
                    }}
                />
            </animated.div>
        )
      })}

      {/* animated hand for guided tour */}
      {isIdle() && <AnimatedHand
          className='guided-tour-hand'
          style={{
            width: tourState && isIdle() ? p
              .interpolate({
                range: widthRange,
                output: widthOutput
              })
              .interpolate(p => `${p}vw`) : '2vw',
            opacity: tourState && isIdle() ? p
              .interpolate({
                range: [0, 1/n, 22/n, 1],
                output: [0, 0.8, 0.8, 0]
              })
              .interpolate(p => `${p}`) : 0,
            left: tourState && isIdle() ? p
              .interpolate({
                range: moveRange,
                output: [10, 10, 1, 1, 3, 3, 5, 5, 53, 53, 52, 52, 42, 42, 72, 72, 26, 26, 89, 89, 1, 1, 10, 10]
              })
              .interpolate(p => `${p}vw`) : '10vw',
            bottom: tourState && isIdle() ? p
              .interpolate({
                range: moveRange,
                output: [5, 5, 4, 4, 3, 3, 0, 0, 4, 4, 20, 20, 65, 65, 42, 42, 42, 42, 1, 1, -1, -1, 5, 5]
              })
              .interpolate(p => `${p}vh`) : '5vh'
          }}
      />}

      {/* video view */}
      {videoOpen && videoUrl !== null ? <Video url={videoUrl} close={handleVideoClose}/> : null}
    </div>
  );
}
