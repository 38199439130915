import React from 'react';
import { DayReport } from '../Service';
import { getColor } from '../../utils/utils';

export interface CircleProps {
  label: string;
  date: Date;
  reports: DayReport[];
  changeDay: (offset: number) => void;
  inactive?: boolean
  animated?: boolean
}

export interface ServiceLogoProps {
  label: string;
  inactive?: boolean
}

function showInnerCircle(label: string) {
  switch (label.toLowerCase()) {
    case 'csa':
      return true;
    default:
      return false;
  }
}

const inactiveColor = '#8191AF'

function ServiceLogo(props: ServiceLogoProps) {
  const logoColor = props.inactive ? inactiveColor : "#72B8EB"
  switch (props.label.toLowerCase()) {
    case 'opc':
    case 'opc/opi': {
      return (
        <g id="opcLogo" transform="translate(270.682501, 267.622501)">
          <path
            id="Shape_4_"
            fill={logoColor}
            d="M58.9-70.2H18.2c-3.6,0-6.6,3-6.6,6.7v41.4c0,3.7,3,6.7,6.6,6.7h40.7
  c3.6,0,6.6-3,6.6-6.7v-41.4C65.4-67.2,62.5-70.2,58.9-70.2z M61.9-22.1c0,1.7-1.4,3.1-3,3.1H18.2c-1.7,0-3-1.4-3-3.1v-41.4
  c0-1.7,1.4-3.1,3-3.1h40.7c1.7,0,3,1.4,3,3.1V-22.1L61.9-22.1z"
          />
          <path
            id="Shape_3_"
            fill={logoColor}
            d="M38.5-61c-9.8,0-17.8,8-17.8,17.8s8,17.8,17.8,17.8s17.8-8,17.8-17.8S48.3-61,38.5-61z
  M38.5-29c-7.2,0-13.2-5.4-14.1-12.4H25c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8h-0.6c0.9-7,6.9-12.4,14.1-12.4
  S51.7-52,52.6-45H52c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h0.6C51.7-34.5,45.7-29,38.5-29z"
          />
          <path
            id="Path"
            fill={logoColor}
            d="M33.5-47.3c-1,0-1.8,0.8-1.8,1.8v4.7c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8v-4.7
  C35.3-46.6,34.5-47.3,33.5-47.3z"
          />
          <path
            id="Path_1_"
            fill={logoColor}
            d="M42.6-47.3c-1,0-1.8,0.8-1.8,1.8v4.7c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8v-4.7
  C44.4-46.6,43.6-47.3,42.6-47.3z"
          />
          <path
            id="Path_2_"
            fill={logoColor}
            d="M63.7-5.4h-1.9c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h1.9c1,0,1.8-0.8,1.8-1.8
  C65.4-4.5,64.6-5.4,63.7-5.4z"
          />
          <path
            id="Shape_2_"
            fill={logoColor}
            d="M52.7-5.3H6.4c-7.1,0-12.9-5.8-12.9-13v-6.1C1-25,6.9-31.3,6.9-39v-12.1
  c0-2.4-2-4.4-4.4-4.4h-3.6v-10.1c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8v10.1h-7.8v-10.1c0-1-0.8-1.8-1.8-1.8
  s-1.8,0.8-1.8,1.8v10.1h-3.6c-2.4,0-4.4,2-4.4,4.4V-39c0,7.9,6.2,14.4,14,14.7v6C-10-9.1-2.6-1.7,6.4-1.7h46.3
  c1,0,1.8-0.8,1.8-1.8C54.5-4.5,53.7-5.3,52.7-5.3z M-9.5-27.9c-6.1,0-11-5-11-11.1v-12.1c0-0.5,0.4-0.8,0.8-0.8H2.5
  c0.5,0,0.8,0.4,0.8,0.8V-39c0,6.1-4.9,11.1-11,11.1H-9.5z"
          />
        </g>
      );
    }
    case 'csa': {
      return (
        <g id="csaLogo" transform="translate(276.292501, 267.877501)">
          <path
            id="Shape"
            fill={logoColor}
            d="M56-72.5c-1.2-4.6-7.5-12.2-37.2-12.2c-14,0-24.2,1.5-30.2,4.5c-6.7,3.3-7.2,7.8-7.2,9.1
  v25.9c0,16.1,8.8,31,23.1,38.9l11.4,6.4c1.8,1,4,1,5.8,0l11.4-6.4c14.2-7.9,23-22.8,23.1-38.9v-25.9C56.1-71.6,56.1-72,56-72.5
  z M50.2-45.3c0,14.1-7.7,27-20.1,33.9L18.7-4.9L7.3-11.3c-12.4-6.9-20.1-19.9-20.1-33.9v-25.9c0,0,0-7.8,31.5-7.8
  c29.5,0,31.5,7.8,31.5,7.8V-45.3z"
          />
          <circle id="Oval_3_" fill="#72B8EB" cx="19.6" cy="-19.7" r="2.7" />
          <path
            id="Shape_1_"
            fill={logoColor}
            d="M19.6-66.9c-1.5,0-2.7,1.3-2.7,3v31.5c0,1.6,1.2,3,2.7,3c1.5,0,2.7-1.3,2.7-3v-31.5
  C22.3-65.6,21.1-66.9,19.6-66.9z"
          />
        </g>
      );
    }
    case 'sta': {
      return (
        <g id="staLogo">
          <path
            id="staLogoPath"
            fill={logoColor} //fill="#69BFFF"
            d="M313.5,216.8c-1-1.5-2.6-2.4-4.4-2.4h-10.5v-24.5c0-2.5-1.8-4.7-4.2-5.2
					c-2.4-0.5-4.9,0.8-5.9,3.1l-15.8,36.8c-0.7,1.6-0.5,3.5,0.4,5c1,1.5,2.6,2.4,4.4,2.4h10.5v24.5c0,2.5,1.8,4.7,4.2,5.2
					c2.4,0.5,4.9-0.8,5.9-3.1l15.8-36.8C314.6,220.1,314.5,218.3,313.5,216.8z M293.3,256.5v-29.8h-15.8l15.8-36.8v29.8h15.8
					L293.3,256.5z"
          />
        </g>
      );
    }
    case 'cc cwe':
    case 'cc ibwt': {
      return (
        <g id="ccLogo">
          <path
            id="Shape_7_"
            fill={logoColor}
            d="M249.5,198.8h6.1c1.5,5.3,6.3,9,11.8,9c5.5,0,10.3-3.7,11.8-9h57.5
  c1.9,0,3.4-1.5,3.4-3.4c0-1.9-1.5-3.4-3.4-3.4h-57.5c-1.5-5.3-6.3-9-11.8-9c-5.5,0-10.3,3.7-11.8,9h-6.1
  c-1.9,0-3.4,1.5-3.4,3.4C246.2,197.3,247.7,198.8,249.5,198.8z M267.4,189.9c3.1,0,5.6,2.5,5.6,5.6s-2.5,5.6-5.6,5.6
  s-5.6-2.5-5.6-5.6S264.3,189.9,267.4,189.9z"
          />
          <path
            id="Shape_6_"
            fill={logoColor}
            d="M336.8,223.5h-32.2c-1.8-4.7-6.4-7.8-11.5-7.8s-9.6,3.1-11.5,7.8h-32.2
  c-1.9,0-3.4,1.5-3.4,3.4c0,1.9,1.5,3.4,3.4,3.4h31.5c1.1,5.8,6.2,10,12.1,10s11-4.2,12.1-10h31.5c1.9,0,3.4-1.5,3.4-3.4
  C340.1,225,338.6,223.5,336.8,223.5z M293.1,233.5c-3.1,0-5.6-2.5-5.6-5.6s2.5-5.6,5.6-5.6c3.1,0,5.6,2.5,5.6,5.6
  S296.2,233.5,293.1,233.5z"
          />
          <path
            id="Shape_5_"
            fill={logoColor}
            d="M336.8,254.8h-6.1c-1.5-5.3-6.3-9-11.8-9c-5.5,0-10.3,3.7-11.8,9h-57.5
  c-1.9,0-3.4,1.5-3.4,3.4c0,1.9,1.5,3.4,3.4,3.4H307c1.5,5.3,6.3,9,11.8,9c5.5,0,10.3-3.7,11.8-9h6.1c1.9,0,3.4-1.5,3.4-3.4
  C340.1,256.3,338.6,254.8,336.8,254.8z M318.9,263.7c-3.1,0-5.6-2.5-5.6-5.6c0-3.1,2.5-5.6,5.6-5.6c3.1,0,5.6,2.5,5.6,5.6
  C324.5,261.2,322,263.7,318.9,263.7z"
          />
        </g>
      );
    }
    default: {
      return null;
    }
  }
}

export function Circle(props: CircleProps) {
  const { label, reports, date, changeDay, inactive} = props;

  const innerCircle = showInnerCircle(label);

  const fullOuterCircle = reports.length > 5;

  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = date.getFullYear();

  let outerCircleType: string;
  label.toLowerCase() === 'sta' || label.toLowerCase() === 'cc ibwt' ? (outerCircleType = 'intra') : (outerCircleType = 'ahead');

  const logoColor = props.inactive ? inactiveColor : "#72B8EB"
  const textColor = props.inactive ? inactiveColor : "#689FDE"

  return (
    <svg
      version="1.1"
      id="CircleReport"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
      fontFamily='TitilliumWeb-Regular, Titillium Web, sans-serif'
      fontWeight='bolder'
      style={{
        maxWidth: '44vh',
      }}
    >
      <g
        id="Page-1"
      >
        <g id="LCD-Display---Wireframe" transform="translate(0.000000, -135.000000)">
          <defs>
          <radialGradient
            id={'Oval_20_' + label.replace(/\s/g, '')}
            cx="1.451"
            cy="647.5979"
            r="0.4037"
            gradientTransform="matrix(633.93 0 0 -633.93 -663.93 410922.625)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" style={{ stopColor: '#67B0E7', stopOpacity: 0.3 }} />
            <stop offset="1" style={{ stopColor: '#67B0E7', stopOpacity: 0 }} />
          </radialGradient>
          </defs>

          <circle id="Oval" fill={'url(#Oval_20_' + label.replace(/\s/g, '') + ')'} cx="255.9" cy="390.9" r="255.9" />
          <g id="TheCircle" transform="translate(-31.000000, 135.000000)">
            <g id="OuterCircle">
              <g id="Kreis-rechts-groß" transform="translate(187.935001, 1.402500)">
                <path
                  id="Combined-Shape"
                  fill={getColor(reports[0].outer)}
                  d="M102.1,42.8c115.8,0,209.7,93.2,209.7,208.2c0,103.2-75.5,188.8-174.7,205.4l0-0.1
  c-0.5,0-1,0.1-1.6,0.1c-9.9,0-17.9-8.2-17.9-18.1c0-8.4,5.8-15.5,13.6-17.4l0-0.1C213.2,407,275.5,336.2,275.5,251
  c0-94.6-76.8-171.4-171.9-172.2l0-0.1c9.3-0.7,16.7-8.5,16.7-18s-7.3-17.2-16.7-18l0,0L102.1,42.8z"
                />
              </g>
              <g id="Kreis-links-groß">
                <path
                  id="Combined-Shape_10_"
                  fill="#3E4B66"
                  d="M290.3,44.6l1.6,0c0,0,0,0,0,0c-0.4,0-0.7,0-1.1,0l-2,0l0,0
  c1.2,0.1,2.4,0.3,3.5,0.6c1.4,1.6,3.9,3,7.3,4.1c3.6,3.3,5.8,8,5.8,13.2c0,6.2-3.2,11.7-7.9,14.9c-1.4,0.6-2.9,1-4.4,1.2
  l-0.6,0.1l0,0.1c0.8,0,1.6,0,2.4,0c-0.3,0.1-0.5,0.3-0.7,0.5c-1.6,0.6-3.3,1.1-5,1.2l-0.4,0l0,0.1c1.2,0,2.5,0,3.7,0.1
  c-0.3,0.3-0.5,0.6-0.7,0.9c-0.8,0-1.7,0-2.5,0c-94.6,0-171.3,76.7-171.3,171.3c0,94,75.7,170.3,169.4,171.3l-1.2,35l-2.8-0.1
  c-111.3-3.6-200.5-95-200.5-207.2c0-112.8,90.2-204.6,202.4-207.2l3.1,0l-1.1,0l1.5,0C289.4,44.6,289.8,44.6,290.3,44.6z"
                />
                <path
                  id="Oval_17_"
                  fill="none"
                  stroke="#2E3847"
                  strokeWidth="3.5343"
                  strokeLinecap="round"
                  d="M283.5,83.5
  c11.8,0,21.4-9.1,21.4-20.4s-9.6-20.4-21.4-20.4"
                />
              </g>
            </g>

            <g id="InnerLogo">
              {label.length > 3 ? (
                <text
                  id="textLogoLong"
                  transform="matrix(1 0 0 1 226.1938 315.1641)"
                  fill={logoColor}
                  fontWeight="lighter"
                  fontSize="36.6318px"
                >
                  {' '}
                  {label.toUpperCase()}{' '}
                </text>
              ) : (
                <text
                  id="textLogo_1_"
                  transform="matrix(1 0 0 1 261.8573 315.1642)"
                  fill={logoColor}
                  fontWeight="lighter"
                  fontSize="36.6318px"
                >
                  {' '}
                  {label.toUpperCase()}{' '}
                </text>
              )}
              <ServiceLogo label={label} inactive={inactive}/>
            </g>

            <g id="OuterCircle4_1_">
              {fullOuterCircle ? (
                <g id="OuterCircles6">
                  {!innerCircle ? (
                    <g id="OuterText6">
                      <text
                        transform="matrix(0.342 0.9397 -0.9397 0.342 429.5966 197.6506)"
                        fill={textColor}
                        fontSize="15.8428px"
                        letterSpacing="1"
                      >
                        {dd}.
                      </text>

                      <text
                        transform="matrix(0.1908 0.9816 -0.9816 0.1908 437.9993 219.7558)"
                        fill={textColor}
                        fontSize="15.8428px"
                        letterSpacing="1"
                      >
                        {mm}.
                      </text>

                      <text
                        transform="matrix(-0.1564 0.9877 -0.9877 -0.1564 443.5768 245.7811)"
                        fill={textColor}
                        fontSize="15.8428px"
                        letterSpacing="1"
                      >
                        {yyyy}
                      </text>

                      <text
                        transform="matrix(0.9397 -0.342 0.342 0.9397 218.7559 118.0529)"
                        fill={textColor}
                        fontSize="15.8428px"
                        letterSpacing="1"
                      >
                        -1D
                      </text>

                      <text
                        transform="matrix(0.6691 -0.7431 0.7431 0.6691 167.929 162.0051)"
                        fill={textColor}
                        fontSize="15.8428px"
                        letterSpacing="1"
                      >
                        -2D
                      </text>

                      <text
                        transform="matrix(0.2588 -0.9659 0.9659 0.2588 138.455 226.2271)"
                        fill={textColor}
                        fontSize="15.8428px"
                        letterSpacing="1"
                      >
                        -3D
                      </text>

                      <text
                        transform="matrix(-0.2079 -0.9781 0.9781 -0.2079 145.3533 299.8975)"
                        fill={textColor}
                        fontSize="15.8428px"
                        letterSpacing="1"
                      >
                        -4D
                      </text>

                      <text
                        transform="matrix(-0.682 -0.7314 0.7314 -0.682 192.5731 369.0599)"
                        fill={textColor}
                        fontSize="15.8428px"
                        letterSpacing="1"
                      >
                        -5D
                      </text>

                      <text
                        transform="matrix(-0.9272 -0.3746 0.3746 -0.9272 255.1296 404.8235)"
                        fill={textColor}
                        fontSize="15.8428px"
                        letterSpacing="1"
                      >
                        -6D
                      </text>
                    </g>
                  ) : null}
                  <g id="OuterCircle6" onClick={() => changeDay(-6)}>
                    <circle id="Oval_9_" fill={getColor(reports[6].outer)} cx="225.2" cy="430.1" r="17" />
                    {reports[6].outer === 'red' ? (
                      <rect id="Rectangle_6_" x="217.2" y="427.8" fill="#3E4963" width="15.9" height="4.6" />
                    ) : null}
                  </g>
                  <g id="OuterCircle5" onClick={() => changeDay(-5)}>
                    <ellipse
                      id="Oval_8_"
                      transform="matrix(0.9993 -3.837651e-02 3.837651e-02 0.9993 -14.5134 6.139)"
                      fill={getColor(reports[5].outer)}
                      cx="152.7"
                      cy="381.1"
                      rx="17"
                      ry="17"
                    />
                    {reports[5].outer === 'red' ? (
                      <rect id="Rectangle_5_" x="144.7" y="378.8" fill="#3E4963" width="15.9" height="4.6" />
                    ) : null}
                  </g>
                  <g id="OuterCircle4" onClick={() => changeDay(-4)}>
                    <circle id="Oval_7_" fill={getColor(reports[4].outer)} cx="106.6" cy="290.6" r="17" />
                    {reports[4].outer === 'red' ? (
                      <rect id="Rectangle_2_" x="98.7" y="288.3" fill="#3E4963" width="15.9" height="4.6" />
                    ) : null}
                  </g>
                  <g id="OuterCircle3" transform="translate(3.625519, 108.829476)" onClick={() => changeDay(-3)}>
                    <circle id="Oval_6_" fill={getColor(reports[3].outer)} cx="106.6" cy="87.2" r="17" />
                    {reports[3].outer === 'red' ? (
                      <rect id="Rectangle" x="98.3" y="84.8" fill="#3E4963" width="15.9" height="4.6" />
                    ) : null}
                  </g>
                  <g id="OuterCircle2" onClick={() => changeDay(-2)}>
                    <circle id="Oval_5_" fill={getColor(reports[2].outer)} cx="153.2" cy="123.1" r="17" />
                    {reports[2].outer === 'red' ? (
                      <rect id="Rectangle_3_" x="145.2" y="120.8" fill="#3E4963" width="15.9" height="4.6" />
                    ) : null}
                  </g>
                  <g id="OuterCircle1" onClick={() => changeDay(-1)}>
                    <circle id="Oval_4_" fill={getColor(reports[1].outer)} cx="219.1" cy="76.3" r="17" />
                    {reports[1].outer === 'red' ? (
                      <rect id="Rectangle_4_" x="211.2" y="74" fill="#3E4963" width="15.9" height="4.6" />
                    ) : null}
                  </g>
                </g>
              ) : (
                <g id="OuterCircles4">
                  {!innerCircle ? (
                    <g id="OuterText">
                      <text
                        transform="matrix(0.866 -0.5 0.5 0.866 199.016 135.4628)"
                        fill={textColor}
                        fontSize="15.4064px"
                        letterSpacing="1"
                      >
                        -1W
                      </text>

                      <text
                        transform="matrix(0.225 0.9744 -0.9744 0.225 426.3861 182.8701)"
                        fill={textColor}
                        fontSize="15.4064px"
                        letterSpacing="1"
                      ></text>

                      <text
                        transform="matrix(6.975647e-02 0.9976 -0.9976 6.975647e-02 436.4911 218.1841)"
                        fill={textColor}
                        fontSize="15.4064px"
                        letterSpacing="1"
                      >
                        {dd}.{mm}.
                      </text>

                      <text
                        transform="matrix(-0.2756 0.9613 -0.9613 -0.2756 440.1788 270.5259)"
                        fill={textColor}
                        fontSize="15.4064px"
                        letterSpacing="1"
                      >
                        {yyyy}
                      </text>

                      <text
                        transform="matrix(0.2588 -0.9659 0.9659 0.2588 142.7343 224.7146)"
                        fill={textColor}
                        fontSize="15.4064px"
                        letterSpacing="1"
                      >
                        -2W
                      </text>

                      <text
                        transform="matrix(-0.4226 -0.9063 0.9063 -0.4226 162.2323 322.4839)"
                        fill={textColor}
                        fontSize="15.4064px"
                        letterSpacing="1"
                      >
                        -3W
                      </text>

                      <text
                        transform="matrix(-0.848 -0.5299 0.5299 -0.848 231.9838 385.9154)"
                        fill={textColor}
                        fontSize="15.4064px"
                        letterSpacing="1"
                      >
                        -4W
                      </text>
                    </g>
                  ) : null}
                  <g id="OuterCircle4_2_" onClick={() => changeDay(-28)}>
                    <circle
                      id="Oval_1_"
                      fill={reports[4] ? getColor(reports[4].outer) : getColor('gray')}
                      cx="192.6"
                      cy="413.5"
                      r="16.9"
                    />
                    {reports[4] && reports[4].outer === 'red' ? (
                      <rect id="Rectangle_10_" x="184.6" y="411.4" fill="#3E4963" width="15.9" height="4.3" />
                    ) : null}
                  </g>
                  <g id="OuterCircle3_1_" onClick={() => changeDay(-21)}>
                    <circle
                      id="Oval_2_"
                      fill={reports[3] ? getColor(reports[3].outer) : getColor('gray')}
                      cx="115.6"
                      cy="324.1"
                      r="16.9"
                    />
                    {reports[3] && reports[3].outer === 'red' ? (
                      <rect id="Rectangle_9_" x="107.6" y="321.8" fill="#3E4963" width="15.9" height="4.6" />
                    ) : null}
                  </g>
                  <g id="OuterCircle2_1_" transform="translate(138.847500, 250.792501)" onClick={() => changeDay(-14)}>
                    <circle
                      id="Oval_18_"
                      fill={reports[2] ? getColor(reports[2].outer) : getColor('gray')}
                      cx="-26.3"
                      cy="-65.3"
                      r="17"
                    />
                    {reports[2] && reports[2].outer === 'red' ? (
                      <rect id="Rectangle_8_" x="-34.3" y="-67.6" fill="#3E4963" width="15.9" height="4.6" />
                    ) : null}
                  </g>
                  <g id="OuterCircle1_1_" onClick={() => changeDay(-7)}>
                    <circle
                      id="Oval_19_"
                      fill={reports[1] ? getColor(reports[1].outer) : getColor('gray')}
                      cx="194.1"
                      cy="88.4"
                      r="16.9"
                    />
                    {reports[1] && reports[1].outer === 'red' ? (
                      <rect id="Rectangle_7_" x="186.4" y="86.1" fill="#3E4963" width="15.9" height="4.6" />
                    ) : null}
                  </g>
                </g>
              )}
            </g>

            {innerCircle ? (
              <g id="Inner">
                <g id="InnerCircle">
                  <g id="Kreis-rechts-klein" transform="translate(185.130001, 39.270000)">
                    <path
                      id="Combined-Shape_11_"
                      fill={getColor(reports[0].inner ?? 'blue')}
                      d="M101.8,46.6c93.5,0,169.3,75,169.3,167.6c0,83-61,151.9-141,165.2l0-0.1
c-0.4,0-0.8,0.1-1.3,0.1c-8,0-14.5-6.6-14.5-14.6c0-6.8,4.7-12.4,11-14l0-0.1c66.1-11.1,116.4-68,116.4-136.6
c0-76.1-62-137.9-138.8-138.6l0-0.1c7.5-0.6,13.5-6.8,13.5-14.5c0-7.6-5.9-13.9-13.5-14.5l0,0L101.8,46.6z"
                    />
                  </g>
                  <g id="Kreis-links-klein" transform="translate(37.867500, 39.270000)">
                    <path
                      id="Combined-Shape_9_"
                      fill="#3E4B66"
                      d="M251.1,48.2l0.3,0l0,0l0,0c1,0.1,1.9,0.3,2.8,0.5c1.1,1.3,3.1,2.4,5.8,3.3
c2.9,2.6,4.6,6.4,4.6,10.6c0,5-2.5,9.3-6.4,11.9c-1.1,0.5-2.2,0.8-3.5,1l-0.5,0.1l0,0.1c0.6,0,1.3,0,1.9,0
c-0.2,0.1-0.4,0.2-0.6,0.4c-1.2,0.5-2.6,0.8-4,1l-0.3,0l0,0.1c1,0,2,0,2.9,0.1c-0.2,0.2-0.4,0.5-0.5,0.7c-0.7,0-1.3,0-2,0
c-75.6,0-136.9,61.3-136.9,136.9c0,75.6,61.2,136.8,136.8,136.9l-1,28l-0.9,0c-90.2-1.5-162.9-75-162.9-165.6
c0-90.2,72-163.5,161.7-165.6L251.1,48.2z"
                    />
                    <path
                      id="Oval_16_"
                      fill="none"
                      stroke="#2E3847"
                      strokeWidth="3.5343"
                      strokeLinecap="round"
                      d="M247.1,79.3
c9.4,0,17.1-7.3,17.1-16.3s-7.7-16.3-17.1-16.3"
                    />
                  </g>
                </g>
                <g id="InnerText">
                  <text
                    transform="matrix(0.342 0.9397 -0.9397 0.342 394.1561 199.2854)"
                    fill={textColor}
                    fontSize="15.446px"
                    letterSpacing="1"
                  >
                    {dd}.
                  </text>

                  <text
                    transform="matrix(0.1908 0.9816 -0.9816 0.1908 402.3479 220.8333)"
                    fill={textColor}
                    fontSize="15.446px"
                    letterSpacing="1"
                  >
                    {mm}.
                  </text>

                  <text
                    transform="matrix(-0.1564 0.9877 -0.9877 -0.1564 407.7844 246.2102)"
                    fill={textColor}
                    fontSize="15.446px"
                    letterSpacing="1"
                  >
                    {yyyy}
                  </text>

                  <text
                    transform="matrix(0.9397 -0.342 0.342 0.9397 230.2982 149.4808)"
                    fill={textColor}
                    fontSize="15.446px"
                    letterSpacing="1"
                  >
                    -1D
                  </text>

                  <text
                    transform="matrix(0.6691 -0.7431 0.7431 0.6691 194.6465 186.1549)"
                    fill={textColor}
                    fontSize="15.446px"
                    letterSpacing="1"
                  >
                    -2D
                  </text>

                  <text
                    transform="matrix(0.2588 -0.9659 0.9659 0.2588 173.6333 234.8671)"
                    fill={textColor}
                    fontSize="15.446px"
                    letterSpacing="1"
                  >
                    -3D
                  </text>

                  <text
                    transform="matrix(-0.2079 -0.9781 0.9781 -0.2079 178.8148 291.246)"
                    fill={textColor}
                    fontSize="15.446px"
                    letterSpacing="1"
                  >
                    -4D
                  </text>

                  <text
                    transform="matrix(-0.682 -0.7314 0.7314 -0.682 215.5819 344.7711)"
                    fill={textColor}
                    fontSize="15.446px"
                    letterSpacing="1"
                  >
                    -5D
                  </text>

                  <text
                    transform="matrix(-0.9272 -0.3746 0.3746 -0.9272 268.8509 367.2895)"
                    fill={textColor}
                    fontSize="15.446px"
                    letterSpacing="1"
                  >
                    -6D
                  </text>
                </g>

                <g id="InnerCircles">
                  <g id="InnerCircle1" onClick={() => changeDay(-1)}>
                    <circle id="Oval_10_" fill={getColor(reports[1].inner ?? 'blue')} cx="234.8" cy="111.7" r="13.6" />
                    {reports[1].inner === 'red' ? (
                      <rect id="Rectangle_12_" x="228.5" y="110.1" fill="#3E4963" width="12.7" height="3.2" />
                    ) : null}
                  </g>
                  <g id="InnerCircle2" onClick={() => changeDay(-2)}>
                    <circle id="Oval_11_" fill={getColor(reports[2].inner ?? 'blue')} cx="182.5" cy="148.3" r="13.6" />
                    {reports[2].inner === 'red' ? (
                      <rect id="Rectangle_11_" x="176.1" y="146.7" fill="#3E4963" width="12.7" height="3.2" />
                    ) : null}
                  </g>
                  <g id="InnerCircle3" transform="translate(39.319707, 122.265651)" onClick={() => changeDay(-3)}>
                    <circle id="Oval_12_" fill={getColor(reports[3].inner ?? 'blue')} cx="106.8" cy="85.2" r="13.6" />
                    {reports[3].inner === 'red' ? (
                      <rect id="Rectangle_1_" x="100.5" y="84.1" fill="#3E4963" width="12.7" height="3.2" />
                    ) : null}
                  </g>
                  <g id="InnerCircle4" onClick={() => changeDay(-4)}>
                    <circle id="Oval_13_" fill={getColor(reports[4].inner ?? 'blue')} cx="142.5" cy="284.1" r="13.6" />
                    {reports[4].inner === 'red' ? (
                      <rect id="Rectangle_13_" x="136.4" y="282.5" fill="#3E4963" width="12.7" height="3.2" />
                    ) : null}
                  </g>
                  <g id="InnerCircle5" onClick={() => changeDay(-5)}>
                    <circle id="Oval_14_" fill={getColor(reports[5].inner ?? 'blue')} cx="180.4" cy="356.2" r="13.6" />
                    {reports[5].inner === 'red' ? (
                      <rect id="Rectangle_14_" x="174" y="354.6" fill="#3E4963" width="12.7" height="3.2" />
                    ) : null}
                  </g>
                  <g id="InnerCircle6" onClick={() => changeDay(-6)}>
                    <circle id="Oval_15_" fill={getColor(reports[6].inner ?? 'blue')} cx="238.6" cy="396" r="13.6" />
                    {reports[6].inner === 'red' ? (
                      <rect id="Rectangle_15_" x="232.2" y="395" fill="#3E4963" width="12.7" height="3.2" />
                    ) : null}
                  </g>
                </g>

                <g id="InnerCircleText">
                  <text
                    transform="matrix(0.2756 0.9613 -0.9613 0.2756 421.0061 186.3879)"
                    fill="#2B313D"
                    fontSize="19.8327px"
                  >
                    INTRA
                  </text>
                  <text
                    transform="matrix(-0.1736 0.9848 -0.9848 -0.1736 437.5081 255.1593)"
                    fill="#2B313D"
                    fontSize="19.8327px"
                  >
                    DAY
                  </text>
                </g>
              </g>
            ) : null}

            <g id="OuterCircleText">
              {outerCircleType === 'ahead' ? (
                fullOuterCircle ? (
                  <g id="dayAhead">
                    <text
                      transform="matrix(0.2756 0.9613 -0.9613 0.2756 461.223 185.3646)"
                      fill="#2B313D"
                      fontSize="19.8327px"
                    >
                      {' '}
                      DAY
                    </text>

                    <text
                      transform="matrix(-0.1045 0.9945 -0.9945 -0.1045 475.0232 231.689)"
                      fill="#2B313D"
                      fontSize="19.8327px"
                    >
                      AHEAD
                    </text>
                  </g>
                ) : (
                  <g id="weekAhead" transform="translate(30.000000, 0.000000)">
                    <text
                      transform="matrix(-0.1736 0.9848 -0.9848 -0.1736 447.163 241.1731)"
                      fill="#1F293C"
                      fontSize="18.0081px"
                    >
                      AHEAD
                    </text>

                    <text
                      transform="matrix(0.2756 0.9613 -0.9613 0.2756 430.2232 184.8856)"
                      fill="#1F293C"
                      fontSize="18.0081px"
                    >
                      WEEK
                    </text>
                  </g>
                )
              ) : (
                <g id="outerIntraDay">
                  <text
                    transform="matrix(-0.218 0.976 -0.976 -0.218 476.0378 255.0726)"
                    fill="#1F293C"
                    fontSize="18.9333px"
                  >
                    DAY
                  </text>

                  <text
                    transform="matrix(0.2319 0.9727 -0.9727 0.2319 461.9498 189.1829)"
                    fill="#1F293C"
                    fontSize="18.9333px"
                  >
                    INTRA
                  </text>
                </g>
              )}
            </g>
          </g>
        </g>
      </g>
      </svg>
  );
}
