import React from 'react';
import { getFormattedDate } from '../utils/utils';
import '../styles/Header.css';

const logo = require('../assets/LogoTSCNET.svg');

interface HeaderProps {
  processDate: Date;
}

export function Header(props: HeaderProps) {
  return (
    <div className="header">
      <div className="header__top">
        <div className="header__top__date"> Process date {getFormattedDate(props.processDate)}</div>
        <div>
          <img alt="logo" className="header__top__logo" src={String(logo)} />
        </div>
      </div>
      <div className="header__divider" />
      <div className="header__bottom">TSCNET information classification: Restricted. Not for public use.</div>
    </div>
  );
}
