import React, {useEffect, useState} from 'react';
import { animated, useSpring } from 'react-spring';

export function KeySymbolsLegend(props: {open?: boolean}) {
  const [keyOpen, setKeyOpen] = useState(props.open);
  const keyProps = useSpring({ height: keyOpen ? '26vh' : '4vh' });

  useEffect(() => {
    if (props.open !== keyOpen) {
      setKeyOpen(props.open)
    }
  }, [props.open]);

  return (
    <animated.div
      style={keyProps}
      className="key-container"
      onClick={() => {
        setKeyOpen(!keyOpen);
      }}
    >
      <svg viewBox="0 0 603 569" version="1.1">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="0_Home-Key-High-Fi" transform="translate(-2722.000000, -1533.000000)">
            <g id="TSCNET_Dashboard-updated-key" transform="translate(2722.000000, 1533.000000)">
              <rect
                id="Rectangle"
                fillOpacity="0.35"
                fill="#415271"
                x="0"
                y="0.38256"
                width="602.7588"
                height="568"
                rx="14.4"
              />
              <text
                id="KPIs-met"
                fontFamily="TitilliumWeb-Regular, Titillium Web"
                fontSize="23.39064"
                fontWeight="normal"
                letterSpacing="1.29947993"
                fill="#8191AF"
              >
                <tspan x="95.36184" y="346.70568">
                  KPIs met
                </tspan>
              </text>
              <g id="Group-3" transform="translate(35.985600, 206.351040)">
                <g id="Group-2" strokeWidth="3.59856">
                  <circle id="Oval" stroke="#6AC0FF" cx="18.89244" cy="18.89244" r="18.89244" />
                  <circle id="Oval" stroke="#4A5E7E" cx="18.89244" cy="18.89244" r="11.69532" />
                </g>
                <text
                  id="day-ahead"
                  fontFamily="TitilliumWeb-Regular, Titillium Web"
                  fontSize="23.39064"
                  fontWeight="normal"
                  letterSpacing="1.29947993"
                  fill="#8191AF"
                >
                  <tspan x="59.37624" y="30.59856">
                    day ahead
                  </tspan>
                </text>
              </g>
              <g id="Group" transform="translate(35.985600, 148.774080)" strokeWidth="3.59856">
                <circle id="Oval" stroke="#4A5E7E" cx="18.89244" cy="18.89244" r="18.89244" />
                <circle id="Oval" stroke="#6AC0FF" cx="18.89244" cy="18.89244" r="11.69532" />
              </g>
              <text
                id="-1d"
                fontFamily="Avenir-Heavy, Avenir"
                fontSize="17.4656837"
                fontWeight="600"
                letterSpacing="1.45547366"
                fill="#689FDE"
              >
                <tspan x="35.9856" y="280.928">
                  -1D
                </tspan>
              </text>
              <text
                id="1-day-in-the-past"
                fontFamily="TitilliumWeb-Regular, Titillium Web"
                fontSize="23.39064"
                fontWeight="normal"
                letterSpacing="1.29947993"
                fill="#8191AF"
              >
                <tspan x="95.36184" y="290.928">
                  1 day in the past
                </tspan>
              </text>
              <text
                id="intra-day"
                fontFamily="TitilliumWeb-Regular, Titillium Web"
                fontSize="23.39064"
                fontWeight="normal"
                letterSpacing="1.29947993"
                fill="#8191AF"
              >
                <tspan x="95.36184" y="181.17192">
                  intra day
                </tspan>
              </text>
              <text
                id="day-ahead"
                fontFamily="TitilliumWeb-Regular, Titillium Web"
                fontSize="23.39064"
                fontWeight="normal"
                letterSpacing="1.29947993"
                fill="#8191AF"
              >
                <tspan x="379.64808" y="236.9496">
                  day ahead
                </tspan>
              </text>
              <text
                id="weekly"
                fontFamily="TitilliumWeb-Regular, Titillium Web"
                fontSize="23.39064"
                fontWeight="normal"
                letterSpacing="1.29947993"
                fill="#8191AF"
              >
                <tspan x="379.64808" y="292.72728">
                  weekly
                </tspan>
              </text>
              <text
                id="intra-day"
                fontFamily="TitilliumWeb-Regular, Titillium Web"
                fontSize="23.39064"
                fontWeight="normal"
                letterSpacing="1.29947993"
                fill="#8191AF"
              >
                <tspan x="379.64808" y="181.17192">
                  intra day
                </tspan>
              </text>
              <text
                id="KPIs-breached"
                fontFamily="TitilliumWeb-Regular, Titillium Web"
                fontSize="23.39064"
                fontWeight="normal"
                letterSpacing="1.29947993"
                fill="#8191AF"
              >
                <tspan x="95.36184" y="400.68408">
                  KPIs breached
                </tspan>
              </text>
              <text
                id="Business-impact"
                fontFamily="TitilliumWeb-Regular, Titillium Web"
                fontSize="23.39064"
                fontWeight="normal"
                letterSpacing="1.29947993"
                fill="#8191AF"
              >
                <tspan x="95.36184" y="456.46176">
                  Business impact
                </tspan>
              </text>
              <circle id="Oval" fill="#6AC0FF" cx="52.17912" cy="330.50136" r="16.19352" />
              <circle id="Oval" fill="#B254CD" cx="52.17912" cy="386.27904" r="16.19352" />
              <text
                id="KPIs-met"
                fontFamily="TitilliumWeb-Regular, Titillium Web"
                fontSize="23.39064"
                fontWeight="normal"
                letterSpacing="1.29947993"
                fill="#8191AF"
              >
                <tspan x="379.64808" y="346.70568">
                  KPIs met
                </tspan>
              </text>
              <text
                id="KPIs-breached"
                fontFamily="TitilliumWeb-Regular, Titillium Web"
                fontSize="23.39064"
                fontWeight="normal"
                letterSpacing="1.29947993"
                fill="#8191AF"
              >
                <tspan x="379.64808" y="402.48336">
                  KPIs breached
                </tspan>
              </text>
              <text
                id="Business-impact"
                fontFamily="TitilliumWeb-Regular, Titillium Web"
                fontSize="23.39064"
                fontWeight="normal"
                letterSpacing="1.29947993"
                fill="#8191AF"
              >
                <tspan x="379.64808" y="456.46176">
                  Business impact
                </tspan>
              </text>
              <g id="Group-4" transform="translate(323.870400, 431.261040)">
                <circle id="Oval" fill="#E36669" cx="15.3864352" cy="15.3864352" r="15.3864352" />
                <rect id="Rectangle" fill="#242E42" x="8.9964" y="14.39424" width="12.59496" height="3.59856" />
              </g>
              <g id="Group-4" transform="translate(35.985600, 431.261040)">
                <circle id="Oval" fill="#E36669" cx="15.3864352" cy="15.3864352" r="15.3864352" />
                <rect id="Rectangle" fill="#242E42" x="8.9964" y="14.39424" width="12.59496" height="3.59856" />
              </g>
              <text
                id="No-data-available"
                fontFamily="TitilliumWeb-Regular, Titillium Web"
                fontSize="23.39064"
                fontWeight="normal"
                letterSpacing="1.29947993"
                fill="#8191AF"
              >
                <tspan x="95.36184" y="512.23944">
                  No data available
                </tspan>
              </text>
              <g id="Group-4" transform="translate(35.985600, 487.038720)" fill="#8191AF">
                <circle id="Oval" cx="15.3864352" cy="15.3864352" r="15.3864352" />
              </g>
              <text
                id="No-data-available"
                fontFamily="TitilliumWeb-Regular, Titillium Web"
                fontSize="23.39064"
                fontWeight="normal"
                letterSpacing="1.29947993"
                fill="#8191AF"
              >
                <tspan x="383.24664" y="512.23944">
                  No data available
                </tspan>
              </text>
              <g id="Group-4" transform="translate(323.870400, 487.038720)" fill="#8191AF">
                <circle id="Oval" cx="15.3864352" cy="15.3864352" r="15.3864352" />
              </g>
              <circle id="Oval" fill="#6AC0FF" cx="340.06392" cy="335.8992" r="16.19352" />
              <polyline
                id="Fill-1"
                fill="#303D56"
                points="347.517437 326.9028 337.365 337.856627 332.609503 333.111263 329.26824 336.630276 337.365 344.8956 350.8596 330.420866 347.517437 326.9028"
              />
              <circle id="Oval" fill="#B254CD" cx="340.06392" cy="391.67688" r="16.19352" />
              <text
                id="Circle"
                fontFamily="TitilliumWeb-Regular, Titillium Web"
                fontSize="23.39064"
                fontWeight="normal"
                letterSpacing="1.94922001"
                fill="#FFFFFF"
              >
                <tspan x="35.9856" y="132.59136">
                  Circle
                </tspan>
              </text>
              <text
                id="Details"
                fontFamily="TitilliumWeb-Regular, Titillium Web"
                fontSize="23.39064"
                fontWeight="normal"
                letterSpacing="1.94922001"
                fill="#FFFFFF"
              >
                <tspan x="322.07112" y="136.18992">
                  Details
                </tspan>
              </text>
              <circle id="Oval" fill="#415271" cx="340.06392" cy="172.16472" r="19.79208" />
              <path
                d="M340.06392,161.36904 C334.101631,161.36904 329.26824,166.202431 329.26824,172.16472 C329.26824,178.127009 334.101631,182.9604 340.06392,182.9604 C346.026209,182.9604 350.8596,178.127009 350.8596,172.16472 C350.8596,166.202431 346.026209,161.36904 340.06392,161.36904 Z M340.06392,181.488262 C334.91467,181.488262 330.740378,177.31397 330.740378,172.16472 C330.740378,167.01547 334.91467,162.841178 340.06392,162.841178 C345.21317,162.841178 349.387462,167.01547 349.387462,172.16472 C349.387462,177.31397 345.21317,181.488262 340.06392,181.488262 Z"
                id="Shape"
                fill="#6AC0FF"
              />
              <path
                d="M344.862,170.771729 L341.26344,170.771729 L341.26344,165.664095 C341.26344,165.279432 340.994918,164.9676 340.66368,164.9676 C340.332442,164.9676 340.06392,165.279432 340.06392,165.664095 L340.06392,172.16472 L344.862,172.16472 C345.193238,172.16472 345.46176,171.852888 345.46176,171.468225 C345.46176,171.083561 345.193238,170.771729 344.862,170.771729 L344.862,170.771729 Z"
                id="Shape"
                fill="#6AC0FF"
              />
              <g
                id="Time-2"
                transform="translate(339.856000, 282.128720) rotate(-270.000000) translate(-339.856000, -282.128720) translate(319.856000, 262.128720)"
              >
                <circle id="Oval" fill="#415271" cx="19.79208" cy="19.79208" r="19.79208" />
                <path
                  d="M8.69954499,18.6796561 C8.69954499,24.8566754 13.7070086,29.864139 19.8840279,29.864139 C26.0610472,29.864139 31.0685108,24.8566754 31.0685108,18.6796561 C31.0685108,12.5026367 26.0610472,7.49517318 19.8840279,7.49517318 C13.7070086,7.49517318 8.69954499,12.5026367 8.69954499,18.6796561 Z M19.8840279,28.3389822 C14.5493294,28.3389822 10.2247017,24.0143546 10.2247017,18.6796561 C10.2247017,13.3449576 14.5493294,9.02032994 19.8840279,9.02032994 C25.2187264,9.02032994 29.543354,13.3449576 29.543354,18.6796561 C29.543354,21.2414687 28.5256779,23.6983561 26.7142029,25.5098311 C24.9027279,27.3213061 22.4458405,28.3389822 19.8840279,28.3389822 Z"
                  id="Shape"
                  fill="#6AC0FF"
                />
                <path
                  d="M18.9485984,14.0431795 C18.6481326,13.7632026 18.1799095,13.7714638 17.8895074,14.061866 C17.5991053,14.3522681 17.590844,14.8204912 17.870821,15.120957 L21.2871721,18.5373081 L17.870821,21.9536593 C17.590844,22.2541251 17.5991053,22.7223482 17.8895074,23.0127503 C18.1799095,23.3031524 18.6481326,23.3114137 18.9485984,23.0314367 L23.442727,18.5373081 L22.3649496,17.4595307 L18.9485984,14.0431795 Z"
                  id="Shape"
                  fill="#6AC0FF"
                />
              </g>
              <g id="Time-2" transform="translate(320.271840, 206.351040)">
                <circle id="Oval" fill="#415271" cx="19.79208" cy="19.79208" r="19.79208" />
                <path
                  d="M19.8840279,7.49517318 C13.7070086,7.49517318 8.69954499,12.5026367 8.69954499,18.6796561 C8.69954499,24.8566754 13.7070086,29.864139 19.8840279,29.864139 C26.0610472,29.864139 31.0685108,24.8566754 31.0685108,18.6796561 C31.0685108,12.5026367 26.0610472,7.49517318 19.8840279,7.49517318 L19.8840279,7.49517318 Z M19.8840279,28.3389822 C14.5493294,28.3389822 10.2247017,24.0143546 10.2247017,18.6796561 C10.2247017,13.3449576 14.5493294,9.02032994 19.8840279,9.02032994 C25.2187264,9.02032994 29.543354,13.3449576 29.543354,18.6796561 C29.543354,21.2414687 28.5256779,23.6983561 26.7142029,25.5098311 C24.9027279,27.3213061 22.4458405,28.3389822 19.8840279,28.3389822 Z"
                  id="Shape"
                  fill="#6AC0FF"
                />
                <path
                  d="M18.9485984,14.0431795 C18.6481326,13.7632026 18.1799095,13.7714638 17.8895074,14.061866 C17.5991053,14.3522681 17.590844,14.8204912 17.870821,15.120957 L21.2871721,18.5373081 L17.870821,21.9536593 C17.590844,22.2541251 17.5991053,22.7223482 17.8895074,23.0127503 C18.1799095,23.3031524 18.6481326,23.3114137 18.9485984,23.0314367 L23.442727,18.5373081 L22.3649496,17.4595307 L18.9485984,14.0431795 Z"
                  id="Shape"
                  fill="#73ACFF"
                />
              </g>
              <circle id="Oval" stroke="#232F46" strokeWidth="3.47351037" cx="340.06392" cy="391.67688" r="8.9964" />
              <polygon
                id="arrow-25-icon"
                fill="#FFFFFF"
                fillRule="nonzero"
                transform={
                  keyOpen ? 'translate(544, 45) rotate(-270) translate(-544, -45) ' : 'translate(500, 586) rotate(-90)'
                }
                points="534 33.4629903 538.239415 29.2 554 45.2 538.239415 61.2 534 56.936932 545.561376 45.2"
              />
              <text
                id="KEY"
                fontFamily="TitilliumWeb-Bold, Titillium Web"
                fontSize="31.5"
                fontWeight="bold"
                letterSpacing="4.7249999"
                fill="#FFFFFF"
              >
                <tspan x="36" y="47.2">
                  KEY
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </svg>
    </animated.div>
  );
}
