export const idleTime = 1000 * 30;
export const blinkDelay = 1000 * 25;
export const blinkDuration = 1000 * 10;
export const blinkInterval = 1000 * 40 + blinkDuration;
export const popupDelay = 1000 * 30;
export const popupDuration = 1000 * 30;
export const popupInterval = 1000 * 25 + popupDuration;
export const tourDelay = 1000 * 30;
export const tourDuration = 1000 * 33;
export const tourInterval = 1000 * 120 + tourDuration;
export const goHomeTimeout = 1000 * 30;
export const goHomeCountdownTime = 1000 * 1;

// tour delay should be bigger then goHomeTimeout + 10sec
