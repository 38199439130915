import React from 'react';
import '../styles/Intro.css';
import { CgYoutube } from 'react-icons/cg';

interface IntroProps {
  serviceLabel: string;
  openVideo: (url: string) => void;
}

interface IntroServices {
  [key: string]: {
    videos: {
      label: string;
      link: string;
    }[];
    description: string;
  };
}

const servicesIntro: IntroServices = {
  csa: {
    videos: [
      {
        label: 'The Common Grid Model',
        link: 'https://youtu.be/1EWYMoyCZ4w',
      },
      {
        label: 'Coordinated security analysis',
        link: 'https://youtu.be/dG3LQUf1EAc',
      },
    ],
    description:
      'The CSA identifies possible security of supply risks. It is mainly based on the N-1 safety principle, the common European security standard for TSOs. The assessments are based on day-ahead and hourly intraday forecasts. If safety concerns on the transmission grid are found, joint countermeasures can be determined, such as changing configurations, using power flow control devices (e.g. phase-shifting transformers) or redispatch measures.'
  },
  'cc cwe': {
    videos: [
      {
        label: 'Coordinated capacity calculation',
        link: 'https://youtu.be/UBFUGL3NT8E',
      },
    ],
    description:
      'CCC is about optimising cross-border transmission capacities while maintaining security of supply, tailor-made for the respective region (here Central Western Europe). In this way, the maximum transmissible capacity is always available to the markets in a day-ahead process. The Common Grid Model and Flow Based methods approved by the regulatory authorities are the basis. '
  },
  'cc ibwt': {
    videos: [
      {
        label: 'Coordinated capacity calculation',
        link: 'https://youtu.be/UBFUGL3NT8E',
      },
    ],
    description:
      'CCC is about optimising cross-border transmission capacities while maintaining security of supply, tailor-made for the respective region (here the TSOs of the Italian Borders Working Table as a part of the European Market Coupling). In this way, the maximum transmissible market capacity is always available to the markets in a day-ahead and an intraday process. The Common Grid Model and methods approved by the regulatory authorities are the basis. '
  },
  'sta': {
    videos: [
      {
        label: 'Short term adequacy forecasts',
        link: 'https://youtu.be/9hvIbyPoSJE',
      },
    ],
    description:
      'For a secure electricity supply and the balance between generation capacity and consumption, the SMTA process ensures that we always have clarity about available generation capacity and possible transmission constraints. This allows TSOs to plan in good time and react appropriately. Forecasting horizons range from several weeks to several days in advance.'
  },
  'opc/opi': {
    videos: [
      {
        label: 'Outage planning coordination',
        link: 'https://youtu.be/zaaR99HB_s0',
      },
    ],
    description:
     'Simultaneous outages of different elements in the power grid put system stability at risk. In the OPC/OPI process, all planned outages of transmission and power generation elements, for example for maintenance purposes, are assessed on a weekly bases to ensure that all safety requirements are met. If necessary, we coordinate the necessary countermeasures with the TSOs and other RSCs.'
  },
};

export function Intro(props: IntroProps) {
  const { serviceLabel, openVideo } = props;

  const getServiceIntro = servicesIntro[serviceLabel.toLowerCase()];

  if (getServiceIntro !== undefined) {
    return (
      <>
        <div className="intro">
          {
            getServiceIntro.videos.map((video) => (
              <div
                key={video.label}
                className="video-button"
                onClick={() => {
                  openVideo(video.link);
                }}
              >
                <CgYoutube className="video-icon" /> &nbsp;
                {video.label}
              </div>
            ))
          }
          <div className="intro__desc">{getServiceIntro.description}</div>
        </div>
      </>
    );
  } else {
    return <div className="intro">No data for this service</div>;
  }
}
