import React, {useState} from 'react';
import {SView} from '../ServiceView';
import {useSpring, animated} from "react-spring";
import {useIdleTimer} from 'react-idle-timer'
import * as timers from "../../constants/intervals"

interface NavWheelProps {
  switchPage: (toPage: SView) => void;
  activePage: SView;
  tourStarted?: boolean
}

export function NavWheel(props: NavWheelProps) {
  const {switchPage, activePage, tourStarted} = props;
  const [state, toggle] = useState(true)
  const {x} = useSpring({from: {x: 1}, x: state ? 1 : 0, config: {duration: timers.blinkDuration}})

  const [timer, setTimer] = useState<any>(null);

  // @ts-ignore
  const handleOnIdle = event => {
    const newTimer = setInterval(() => {
      if (isIdle() && !tourStarted) {
        toggle(state => !state)
      }
    }, timers.blinkInterval);
    setTimer(newTimer)
  }

  // @ts-ignore
  const handleOnActive = event => {
    clearInterval(timer)
    setTimer(null)
  }

  const {isIdle} = useIdleTimer({
    timeout: timers.idleTime, // to change!
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500
  })

  const switchToDetail = () => {
    switchPage(SView.Details);
  };

  const switchToHistory = () => {
    switchPage(SView.History);
  };

  const switchToInfo = () => {
    switchPage(SView.Intro);
  };

  return (
    <svg width="28vh" height="28vh" viewBox="0 0 292 292" version="1.1">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Styles" transform="translate(-260.000000, -1654.000000)">
          <g id="NAV-Wheel" transform="translate(260.000000, 1654.000000)">
            <circle id="Oval" fill="#415271" cx="146" cy="146" r="146"/>
            <circle id="Oval" fill="#232F46" opacity="0.545735677" cx="146" cy="146" r="74.5"/>
            <g onClick={switchToDetail}>
              {
                !tourStarted && isIdle()
                  ? <animated.text
                    cursor="pointer"
                    id="Detail"
                    transform="translate(229.000000, 65.750000) rotate(-45.000000) translate(-229.000000, -65.750000) "
                    fontFamily="TitilliumWeb-Regular, Titillium Web"
                    fontWeight={activePage === SView.Details ? 'bold' : 'normal'}
                    letterSpacing="1.25"
                    fill="#FFFFFF"
                    style={{
                      fontSize: x
                        .interpolate({
                          range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                          output: [15, 19, 13, 21, 13, 21, 17, 15]
                        })
                        .interpolate(x => `${x}px`)
                    }}
                  >
                    <tspan x="197" y="66.5" onClick={switchToDetail}>
                      Detail
                    </tspan>
                  </animated.text>
                  : <text
                    cursor="pointer"
                    id="Detail"
                    transform="translate(229.000000, 65.750000) rotate(-45.000000) translate(-229.000000, -65.750000) "
                    fontFamily="TitilliumWeb-Regular, Titillium Web"
                    fontSize="15"
                    fontWeight={activePage === SView.Details ? 'bold' : 'normal'}
                    letterSpacing="1.25"
                    fill="#FFFFFF"
                  >
                    <tspan x="197" y="66.5" onClick={switchToDetail}>
                      Detail
                    </tspan>
                  </text>
              }
            </g>
            <g
              onClick={switchToHistory}
            >
              {
                !tourStarted && isIdle()
                ? <animated.text
                    cursor="pointer"
                    id="History"
                    transform="translate(254.000000, 107.250000) rotate(-21.000000) translate(-254.000000, -107.250000) "
                    fontFamily="TitilliumWeb-Regular, Titillium Web"
                    fontSize="15"
                    fontWeight={activePage === SView.History ? 'bold' : 'normal'}
                    letterSpacing="1.25"
                    fill="#FFFFFF"
                    style={{
                      //width: x.interpolate({ range: [0, 1], output: [0.3, 1] }),
                      fontSize: x
                        .interpolate({
                          range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                          output: [15, 19, 13, 21, 13, 21, 17, 15]
                        })
                        .interpolate(x => `${x}px`)
                    }}
                  >
                    <tspan x="222" y="108">
                      History
                    </tspan>
                  </animated.text>
                  : <text
                    cursor="pointer"
                    id="History"
                    transform="translate(254.000000, 107.250000) rotate(-21.000000) translate(-254.000000, -107.250000) "
                    fontFamily="TitilliumWeb-Regular, Titillium Web"
                    fontSize="15"
                    fontWeight={activePage === SView.History ? 'bold' : 'normal'}
                    letterSpacing="1.25"
                    fill="#FFFFFF"
                  >
                    <tspan x="222" y="108">
                      History
                    </tspan>
                  </text>
              }

            </g>
            <g onClick={switchToInfo}>
              {!tourStarted && isIdle()
              ? <animated.text
                  cursor="pointer"
                  id="Intro"
                  transform="translate(195.373248, 41.517973) rotate(-63.000000) translate(-195.373248, -41.517973) "
                  fontFamily="TitilliumWeb-Regular, Titillium Web"
                  fontSize="15"
                  fontWeight={activePage === SView.Intro ? 'bold' : 'normal'}
                  letterSpacing="1.25"
                  fill="#FFFFFF"
                  style={{
                    //width: x.interpolate({ range: [0, 1], output: [0.3, 1] }),
                    fontSize: x
                      .interpolate({
                        range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                        output: [15, 19, 13, 21, 13, 21, 17, 15]
                      })
                      .interpolate(x => `${x}px`)
                  }}
                >
                  <tspan x="163.373248" y="42.2679726">
                    Intro
                  </tspan>
                </animated.text>
                : <text
                  cursor="pointer"
                  id="Intro"
                  transform="translate(195.373248, 41.517973) rotate(-63.000000) translate(-195.373248, -41.517973) "
                  fontFamily="TitilliumWeb-Regular, Titillium Web"
                  fontSize="15"
                  fontWeight={activePage === SView.Intro ? 'bold' : 'normal'}
                  letterSpacing="1.25"
                  fill="#FFFFFF"
                >
                  <tspan x="163.373248" y="42.2679726">
                    Intro
                  </tspan>
                </text>
              }

            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
