import React from 'react';
import { CalendarResponse, fetchCalender, fetchServices, getDjangoToken, ServicesResponse } from '../utils/api';
import { emptyServicesResponse, getDateKey, getInitDay, defaultServices, sortServices } from '../utils/utils';
import { Header } from './Header';
import { ServiceView } from './ServiceView';
import { services } from '../constants/services'

interface DashboardProps {
  msalToken: string | null | undefined;
  refreshMsalToken: (updateApiToken: typeof getDjangoToken, callback: (authToken: string) => void) => void;
}

interface DashboardState {
  selectedDateString: string;
  selectedDate: Date;
  serviceData: ServicesResponse;
  calendarColoring: CalendarResponse;
  apiToken: string | null | undefined;
}

export class Dashboard extends React.Component<DashboardProps, DashboardState> {
  constructor(props: DashboardProps) {
    super(props);
    const initDate = getInitDay();
    this.state = {
      selectedDate: initDate,
      selectedDateString: getDateKey(initDate),
      serviceData: emptyServicesResponse,
      apiToken: undefined,
      calendarColoring: {},
    };
  }

  componentDidMount(): void {
    if (this.props.msalToken) {
      getDjangoToken(this.props.msalToken, this.updateApiToken, this.updateMsalToken);
    }
  }

  updateServices = (response: ServicesResponse) => {
    if (response[getDateKey(this.state.selectedDate)]) {
      this.setState({
        serviceData: response,
        selectedDateString: getDateKey(this.state.selectedDate),
      });
    }
  };

  updateCalendar = (response: CalendarResponse) => {
    this.setState({ calendarColoring: response });
  };

  updateApiToken = (apiToken: string) => {
    this.setState({
      apiToken: apiToken,
    });
    fetchServices(this.state.selectedDate, apiToken, this.updateServices);
    fetchCalender(apiToken, this.updateCalendar);
  };

  updateMsalToken = () => {
    this.props.refreshMsalToken(getDjangoToken, this.updateApiToken);
  };

  // change day through the calendar
  handleChangeDayPicker = (newDate: Date | undefined) => {
    if (newDate !== undefined) {
      this.setState({ selectedDate: newDate });
      if (this.state.apiToken) {
        fetchServices(newDate, this.state.apiToken, this.updateServices);
      }
    }
  };

  // change day through tap on little circles in visualisation
  changeDay = (offset: number) => {
    const newDate = new Date(this.state.selectedDate);
    newDate.setDate(newDate.getDate() + offset);
    this.setState({ selectedDate: newDate });
    if (this.state.apiToken) {
      fetchServices(newDate, this.state.apiToken, this.updateServices);
    }
  };

  getServices = () => {
    const { selectedDateString, serviceData } = this.state;
    const dayReport = serviceData[selectedDateString];
    const res = services.map((serviceLabel) => {
      if (dayReport !== undefined) {
        const serviceReport = dayReport.services.filter((service) => service.label.toLowerCase() === serviceLabel);
        if (serviceReport.length > 0 && serviceReport[0].coloring.names.outer !== null) {
          return serviceReport[0];
        }
      }
      return defaultServices[serviceLabel];
    });
    return res;
  };

  render() {
    const { selectedDate, calendarColoring } = this.state;
    return (
      <div>
        <Header processDate={selectedDate} />
        <div>
          <ServiceView
            services={this.getServices().sort(sortServices)}
            changeDay={this.changeDay}
            changeCalendarDate={this.handleChangeDayPicker}
            currentDay={selectedDate}
            calendarColoring={calendarColoring}
          />
        </div>
      </div>
    );
  }
}
