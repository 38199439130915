import React from 'react';
import './App.css';
import { Login } from './components/Login';
import { authInit, msalAgent, refreshToken, tokenRequest } from './utils/authConfig';
import * as msal from 'msal';
import { getDjangoToken } from './utils/api';
import { Dashboard } from './components/Dashboard';

interface AppState {
  authToken: string | undefined;
  isAuthorized: boolean;
}

export class App extends React.Component<any, AppState> {
  constructor(props: any) {
    super(props);

    this.state = {
      authToken: undefined,
      isAuthorized: false,
    };

    msalAgent.handleRedirectCallback(this.authCallback);
    this.updateAuthStatus = this.updateAuthStatus.bind(this);
  }

  componentDidMount(): void {
    authInit(this.updateAuthStatus);
  }

  updateAuthStatus = (result: boolean, token: string | undefined) => {
    this.setState({ isAuthorized: result, authToken: token });
  };

  //handle redirect response
  authCallback(error: Error | undefined, response: msal.AuthResponse | undefined) {
    const updateAuthStatus = this.updateAuthStatus;

    msalAgent
      .acquireTokenSilent(tokenRequest)
      .then(function (accessTokenResponse) {
        // Acquire token silent success
        // Call API with token
        console.log(accessTokenResponse);
        const token = accessTokenResponse.accessToken;
        console.log('callback, acquired silent token: ' + token);
        updateAuthStatus(true, token);
      })
      .catch(function (error) {
        //Acquire token silent failure, and send an interactive request
        console.log('silent call did not work in callback');
        console.log(error);
      });
  }

  refreshMsalToken = (updateApiToken: typeof getDjangoToken, callback: (authToken: string) => void) => {
    refreshToken(updateApiToken, callback);
  };

  render() {
    const { isAuthorized, authToken } = this.state;
    return (
      <div className="App" id="App">
        {isAuthorized ? <Dashboard msalToken={authToken} refreshMsalToken={this.refreshMsalToken} /> : <Login />}
      </div>
    );
  }
}

export default App;
