import React from 'react';
import '../../styles/SmallCalendar.css';
import { monthNames } from '../../utils/utils';

export function SmallCalendar(props: { monthColoring: string[]; firstDayOfMonth: Date }) {
  const { firstDayOfMonth, monthColoring } = props;

  const getClassName = (index: number) => {
    const offset = firstDayOfMonth.getDay() - 1;
    if (
      index - offset > 0 &&
      new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth() + 1, 0).getDate() >= index - offset
    ) {
      if (monthColoring.length >= index - offset) {
        switch (monthColoring[index - offset - 1]) {
          case 'red':
            return 'redCircle';
          case 'purple':
            return 'purpleCircle';
          case 'blue':
            return 'blueCircle';
          default:
            return 'circle';
        }
      }
      return 'circle';
    }
    return 'defaultCircle';
  };

  return (
    <div>
      {monthNames[firstDayOfMonth.getMonth()] + '  ' + firstDayOfMonth.getFullYear().toString().slice(2, 4)}
      <svg version="1.1" id="Слой_1" x="0px" y="0px" viewBox="0 0 372 320" className="small-calendar-svg">
        <g id="week6">
          <circle id="d1_5_" className={getClassName(35)} cx="29.5" cy="290.4" r="22.1" />
          <circle id="d2_5_" className={getClassName(36)} cx="81.7" cy="290.4" r="22.1" />
          <circle id="d3_5_" className={getClassName(37)} cx="133.8" cy="290.4" r="22.1" />
          <circle id="d4_5_" className={getClassName(38)} cx="186" cy="290.4" r="22.1" />
          <circle id="d5_5_" className={getClassName(39)} cx="238.2" cy="290.4" r="22.1" />
          <circle id="d6_5_" className={getClassName(40)} cx="290.3" cy="290.4" r="22.1" />
          <circle id="d7_5_" className={getClassName(41)} cx="342.5" cy="290.4" r="22.1" />
        </g>
        <g id="week5">
          <circle id="d1_4_" className={getClassName(28)} cx="29.5" cy="238.3" r="22.1" />
          <circle id="d2_4_" className={getClassName(29)} cx="81.7" cy="238.3" r="22.1" />
          <circle id="d3_4_" className={getClassName(30)} cx="133.8" cy="238.3" r="22.1" />
          <circle id="d4_4_" className={getClassName(31)} cx="186" cy="238.3" r="22.1" />
          <circle id="d5_4_" className={getClassName(32)} cx="238.2" cy="238.3" r="22.1" />
          <circle id="d6_4_" className={getClassName(33)} cx="290.3" cy="238.3" r="22.1" />
          <circle id="d7_4_" className={getClassName(34)} cx="342.5" cy="238.3" r="22.1" />
        </g>
        <g id="week4">
          <circle id="d1_3_" className={getClassName(21)} cx="29.5" cy="186.2" r="22.1" />
          <circle id="d2_3_" className={getClassName(22)} cx="81.7" cy="186.2" r="22.1" />
          <circle id="d3_3_" className={getClassName(23)} cx="133.8" cy="186.2" r="22.1" />
          <circle id="d4_3_" className={getClassName(24)} cx="186" cy="186.2" r="22.1" />
          <circle id="d5_3_" className={getClassName(25)} cx="238.2" cy="186.2" r="22.1" />
          <circle id="d6_3_" className={getClassName(26)} cx="290.3" cy="186.2" r="22.1" />
          <circle id="d7_3_" className={getClassName(27)} cx="342.5" cy="186.2" r="22.1" />
        </g>
        <g id="week3">
          <circle id="d1_2_" className={getClassName(14)} cx="28.6" cy="134" r="22.1" />
          <circle id="d2_2_" className={getClassName(15)} cx="80.7" cy="134" r="22.1" />
          <circle id="d3_2_" className={getClassName(16)} cx="132.9" cy="134" r="22.1" />
          <circle id="d4_2_" className={getClassName(17)} cx="185" cy="134" r="22.1" />
          <circle id="d5_2_" className={getClassName(18)} cx="237.2" cy="134" r="22.1" />
          <circle id="d6_2_" className={getClassName(19)} cx="289.3" cy="134" r="22.1" />
          <circle id="d7_2_" className={getClassName(20)} cx="341.5" cy="134" r="22.1" />
        </g>
        <g id="week2">
          <circle id="d1_1_" className={getClassName(7)} cx="28.6" cy="81.8" r="22.1" />
          <circle id="d2_1_" className={getClassName(8)} cx="80.7" cy="81.8" r="22.1" />
          <circle id="d3_1_" className={getClassName(9)} cx="132.9" cy="81.8" r="22.1" />
          <circle id="d4_1_" className={getClassName(10)} cx="185" cy="81.8" r="22.1" />
          <circle id="d5_1_" className={getClassName(11)} cx="237.2" cy="81.8" r="22.1" />
          <circle id="d6_1_" className={getClassName(12)} cx="289.3" cy="81.8" r="22.1" />
          <circle id="d7_1_" className={getClassName(13)} cx="341.5" cy="81.8" r="22.1" />
        </g>
        <g id="week1">
          <circle id="d1" className={getClassName(0)} cx="28.6" cy="29.7" r="22.1" />
          <circle id="d2" className={getClassName(1)} cx="80.7" cy="29.7" r="22.1" />
          <circle id="d3" className={getClassName(2)} cx="132.9" cy="29.7" r="22.1" />
          <circle id="d4" className={getClassName(3)} cx="185" cy="29.7" r="22.1" />
          <circle id="d5" className={getClassName(4)} cx="237.2" cy="29.7" r="22.1" />
          <circle id="d6" className={getClassName(5)} cx="289.3" cy="29.7" r="22.1" />
          <circle id="d7" className={getClassName(6)} cx="341.5" cy="29.7" r="22.1" />
        </g>
      </svg>
    </div>
  );
}
