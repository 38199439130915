import { getDateStringForAPICall } from './utils';
import { trackPromise } from 'react-promise-tracker';

export interface KPIData {
  label: string;
  kpiType: string;
  impact: string;
  timeFrame: string;
  owner: string;
  message: string | null;
  threshold: string;
  difference: string;
}

export interface ServiceCircleReport {
  inner?: string;
  outer: string;
  date: string;
}

export interface ServiceKPIDayReport {
  [key: string]: string;
}

export interface ServiceKPIReport {
  [key: string]: ServiceKPIDayReport;
}

export interface ServiceReport {
  date: string;
  label: string;
  kpis: KPIData[];
  coloring: {
    names: {
      inner?: string;
      outer: string;
    };
    reports: ServiceCircleReport[];
    worstOfSameKpis: ServiceKPIReport | null;
  };
}
export interface DayReport {
  mapColoring: string;
  services: ServiceReport[];
}

export interface ServicesResponse {
  [id: string]: DayReport;
}

export interface CalendarResponse {
  [id: string]: CalendarServiceResponse;
}

export interface CalendarServiceResponse {
  [id: string]: string[];
}

export function fetchServices(
  selectedDate: Date,
  authToken: string,
  callback: (response: ServicesResponse) => void,
): ServicesResponse | null {
  const dateString = getDateStringForAPICall(selectedDate);
  const requestHeaders = new Headers({ Authorization: 'JWT ' + authToken });
  trackPromise(
    fetch(process.env.REACT_APP_BASE_URL + 'services/?from=' + dateString + '&to=' + dateString, {
      method: 'GET',
      headers: requestHeaders,
    })
      .then((response) => {
        return response.json();
      })
      .then((data: ServicesResponse) => {
        callback(data);
        return data;
      }),
  );
  return null;
}

export function fetchCalender(
  authToken: string,
  callback: (response: CalendarResponse) => void,
): ServicesResponse | null {
  const requestHeaders = new Headers({ Authorization: 'JWT ' + authToken });
  trackPromise(
    fetch(process.env.REACT_APP_BASE_URL + 'calendar/', { method: 'GET', headers: requestHeaders })
      .then((response) => {
        return response.json();
      })
      .then((data: CalendarResponse) => {
        callback(data);
        return data;
      }),
  );
  return null;
}

export function getDjangoToken(accessToken: string, success: (authToken: string) => void, fail?: () => void) {
  //console.log("getDjangoToken with input: " + accessToken)
  trackPromise(
    fetch(process.env.REACT_APP_BASE_URL + 'auth/microsoft/', {
      method: 'POST',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        access_token: `${accessToken}`,
      }),
    })
      .then((response) => {
        // console.log(response)
        switch (response.status) {
          case 200:
            return response.json();
          case 403:
            if (fail) {
              fail();
            }
        }
      })
      .then((data) => {
        success(data.token);
      }),
  );
}
