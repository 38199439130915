import React from 'react';
import { getColor } from '../../utils/utils';

export function DayIcon(props: { dayLabel: string; status: string }) {
  return (
    <>
      {props.dayLabel.toLowerCase().match('ahead') ? (
        <DayAheadIcon status={props.status} />
      ) : (
        <InitDayIcon status={props.status} />
      )}
    </>
  );
}

function InitDayIcon(props: { status: string }) {
  return (
    <svg  viewBox="0 0 40 40" version="1.1">
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="tablet-case-1" transform="translate(-395.000000, -1127.000000)">
          <g id="Time-1" transform="translate(395.000000, 1127.000000)">
            <circle id="Oval" fill="#415271" cx="19.9499991" cy="19.9499991" r="19.9499991" />
            <path
              d="M20.4749991,8.39999962 C14.38606,8.39999962 9.44999957,13.33606 9.44999957,19.4249991 C9.44999957,25.5139382 14.38606,30.4499986 20.4749991,30.4499986 C26.5639382,30.4499986 31.4999986,25.5139382 31.4999986,19.4249991 C31.4999986,13.33606 26.5639382,8.39999962 20.4749991,8.39999962 Z M20.4749991,28.9465896 C15.2163699,28.9465896 10.9534086,24.6836283 10.9534086,19.4249991 C10.9534086,14.1663699 15.2163699,9.90340864 20.4749991,9.90340864 C25.7336283,9.90340864 29.9965895,14.1663699 29.9965895,19.4249991 C29.9965895,24.6836283 25.7336283,28.9465896 20.4749991,28.9465896 Z"
              id="Shape"
              fill={getColor(props.status)}
            />
            <path
              d="M25.5499988,18.5274185 L21.349999,18.5274185 L21.349999,13.3112897 C21.349999,12.9184549 21.0365984,12.5999994 20.6499991,12.5999994 C20.2633998,12.5999994 19.9499991,12.9184549 19.9499991,13.3112897 L19.9499991,19.9499991 L25.5499988,19.9499991 C25.9365981,19.9499991 26.2499988,19.6315436 26.2499988,19.2387088 C26.2499988,18.845874 25.9365981,18.5274185 25.5499988,18.5274185 L25.5499988,18.5274185 Z"
              id="Shape"
              fill={getColor(props.status)}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

function DayAheadIcon(props: { status: string }) {
  return (
    <svg  viewBox="0 0 40 40" version="1.1">
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="tablet-case-1" transform="translate(-395.000000, -1305.000000)">
          <g id="Time-2" transform="translate(395.000000, 1305.000000)">
            <circle id="Oval" fill="#415271" cx="19.9499991" cy="19.9499991" r="19.9499991" />
            <g id="Group-7" transform="translate(8.400000, 8.400000)" fill={getColor(props.status)}>
              <path
                d="M11.2737228,2.29150032e-13 C5.04741763,2.43714508e-13 -2.49933407e-12,5.04741763 -2.49933407e-12,11.2737228 C-2.49933407e-12,17.500028 5.04741763,22.5474456 11.2737228,22.5474456 C17.500028,22.5474456 22.5474456,17.500028 22.5474456,11.2737228 C22.5474456,5.04741763 17.500028,2.43714508e-13 11.2737228,2.43714508e-13 L11.2737228,2.29150032e-13 Z M11.2737228,21.0101198 C5.89645924,21.0101198 1.53732584,16.6509864 1.53732584,11.2737228 C1.53732584,5.89645924 5.89645924,1.53732584 11.2737228,1.53732584 C16.6509864,1.53732584 21.0101198,5.89645924 21.0101198,11.2737228 C21.0101198,13.8559758 19.9843238,16.3324665 18.1583951,18.1583951 C16.3324665,19.9843238 13.8559758,21.0101198 11.2737228,21.0101198 Z"
                id="Shape"
              />
              <path
                d="M10.3308296,6.60025226 C10.0279664,6.3180414 9.55600744,6.32636857 9.26328823,6.61908778 C8.97056901,6.911807 8.96224184,7.383766 9.2444527,7.68662919 L12.6880626,11.1302391 L9.2444527,14.5738489 C8.96224184,14.8767121 8.97056901,15.3486711 9.26328823,15.6413903 C9.55600744,15.9341096 10.0279664,15.9424367 10.3308296,15.6602259 L14.8608164,11.1302391 L13.7744395,10.0438621 L10.3308296,6.60025226 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
