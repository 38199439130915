import React from 'react';
import '../styles/Video.css';
import ReactPlayer from 'react-player';
import { IoMdArrowRoundBack } from 'react-icons/io';

interface VideoProps {
  url: string;
  close: () => void;
}

export function Video(props: VideoProps) {
  const { url, close } = props;
  return (
    <div className="popup">
      <div className="video">
        <ReactPlayer className="video" url={url} controls={true} />
      </div>
      <div className="close-button" onClick={close}>
        <IoMdArrowRoundBack className="close-button__icon" />
      </div>
    </div>
  );
}
