import React from 'react';
import '../../styles/Windmill.css';

export function AnimatedWindmill() {
  return (
    <svg version="1.1" id="Background" x="0px" y="0px" viewBox="0 0 803 286">
      <title>Background Windmill</title>
      <desc>Created with Sketch.</desc>
      <g id="Page">
        <g id="Styles" transform="translate(-77.000000, -3330.000000)">
          <g id="Background-Windmill" transform="translate(77.000000, 3330.000000)">
            <g id="Mill-7-s" transform="translate(73.710000, 47.970000)" className="st0">
              <g id="Blades_5_" className="spinning-part spinning-part--7">
                <g id="Blade-3_6_">
                  <polygon id="Fill-173_23_" className="st1"
                           points="682,107.2 680.2,110.4 688.5,115.1 690.3,112 						"/>
                  <polygon
                    id="Fill-174_23_"
                    className="st2"
                    points="681.1,108.8 680.2,110.4 688.5,115.1 689.4,113.5 						"
                  />
                  <path
                    id="Fill-175_23_"
                    className="st3"
                    d="M721.9,135.4c1.3,0.7,2.9,0.2,3.6-1c0.7-1.2,0.3-2.8-0.9-3.6l-31.7-20.2
c-2.3-1.4-5-1.2-6,0.6l-0.9,1.6c-1,1.8,0.2,4.3,2.5,5.5L721.9,135.4"
                  />
                  <path
                    id="Fill-176_23_"
                    className="st1"
                    d="M725.5,134.4l-39.1-22.6l0.5-0.8c1-1.8,3.8-2,6-0.6l31.7,20.2
C725.9,131.5,726.3,133.1,725.5,134.4L725.5,134.4"
                  />
                </g>
                <g id="Blade-2_5_">
                  <polygon id="Fill-173_22_" className="st1"
                           points="677.7,110.4 675.9,107.2 667.6,112 669.5,115.1 						"/>
                  <polygon id="Fill-174_22_" className="st2"
                           points="676.8,108.8 675.9,107.2 667.6,112 668.6,113.5 						"/>
                  <path
                    id="Fill-175_22_"
                    className="st3"
                    d="M633.4,130.8c-1.2,0.8-1.6,2.4-0.9,3.6c0.7,1.2,2.3,1.7,3.6,1l33.4-17.4
c2.4-1.2,3.6-3.7,2.5-5.5L671,111c-1-1.8-3.8-2-6-0.5L633.4,130.8"
                  />
                  <path
                    id="Fill-176_22_"
                    className="st1"
                    d="M632.4,134.4l39.1-22.6l0.5,0.8c1,1.8-0.2,4.3-2.5,5.5l-33.3,17.4
C634.8,136.2,633.2,135.7,632.4,134.4L632.4,134.4"
                  />
                </g>
                <g id="Blade-1_5_">
                  <polygon id="Fill-173_21_" className="st1"
                           points="677.1,105 680.8,105 680.8,95.5 677.1,95.5 						"/>
                  <polygon id="Fill-174_21_" className="st2"
                           points="678.9,105 680.8,105 680.8,95.5 678.9,95.5 						"/>
                  <path
                    id="Fill-175_21_"
                    className="st3"
                    d="M681.6,56.4c-0.1-1.5-1.3-2.6-2.7-2.6c-1.4,0-2.6,1.2-2.7,2.6l-1.6,37.6
c-0.1,2.7,1.5,4.9,3.5,4.9l1.8,0c2,0,3.6-2.3,3.5-4.9L681.6,56.4"
                  />
                  <path
                    id="Fill-176_21_"
                    className="st1"
                    d="M678.9,53.7v45.1H678c-2,0-3.6-2.3-3.5-4.9l1.6-37.6C676.2,54.9,677.4,53.7,678.9,53.7
L678.9,53.7"
                  />
                </g>
                <path
                  id="Fill-187_11_"
                  className="st4"
                  d="M683.9,107.6c0,2.7-2.2,5-5,5c-2.7,0-5-2.2-5-5c0-2.8,2.2-5,5-5
C681.7,102.6,683.9,104.9,683.9,107.6"
                />
                <path
                  id="Fill-188_11_"
                  className="st5"
                  d="M681.9,107.6c0,1.6-1.3,3-3,3c-1.6,0-3-1.3-3-3c0-1.6,1.3-3,3-3
C680.6,104.7,681.9,106,681.9,107.6"
                />
              </g>
              <g id="Leg_5_">
                <path
                  id="Fill-185_11_"
                  className="st1"
                  d="M684.5,229.9h-11.2l2.7-110.7c0-1.5,1.3-2.8,2.9-2.8c1.5,0,2.8,1.2,2.9,2.8L684.5,229.9
"
                />
                <path id="Fill-186_11_" className="st3" d="M684.5,229.9h-5.6V116.4c1.5,0,2.8,1.2,2.9,2.8L684.5,229.9"/>
              </g>
            </g>
            <g id="Mill-6-m" transform="translate(73.710000, 47.970000)" className="st0">
              <g id="Blades_1_" className="spinning-part spinning-part--6">
                <g id="Blade-3_1_">
                  <polygon id="Fill-173_11_" className="st1"
                           points="537.5,67.1 535.2,71.2 545.8,77.4 548.2,73.3 						"/>
                  <polygon id="Fill-174_11_" className="st2"
                           points="536.3,69.2 535.2,71.2 545.8,77.4 547,75.3 						"/>
                  <path
                    id="Fill-175_11_"
                    className="st3"
                    d="M589,103.5c1.7,0.9,3.7,0.3,4.7-1.3c0.9-1.6,0.4-3.7-1.2-4.7l-40.9-26.1
c-2.9-1.9-6.5-1.6-7.8,0.7l-1.2,2c-1.3,2.3,0.2,5.5,3.3,7.1L589,103.5"
                  />
                  <path
                    id="Fill-176_11_"
                    className="st1"
                    d="M593.6,102.2l-50.4-29.1l0.6-1c1.3-2.3,4.8-2.6,7.8-0.7l40.9,26
C594,98.5,594.6,100.5,593.6,102.2L593.6,102.2"
                  />
                </g>
                <g id="Blade-2_1_">
                  <polygon id="Fill-173_10_" className="st1"
                           points="532,71.2 529.6,67.1 518.9,73.3 521.3,77.4 						"/>
                  <polygon id="Fill-174_10_" className="st2"
                           points="530.8,69.2 529.6,67.1 518.9,73.3 520.1,75.3 						"/>
                  <path
                    id="Fill-175_10_"
                    className="st3"
                    d="M474.8,97.6c-1.6,1-2.1,3.1-1.2,4.7c0.9,1.6,3,2.2,4.7,1.3l43-22.4
c3.1-1.6,4.6-4.8,3.3-7.1l-1.2-2c-1.3-2.3-4.9-2.6-7.8-0.7L474.8,97.6"
                  />
                  <path
                    id="Fill-176_10_"
                    className="st1"
                    d="M473.5,102.2l50.4-29.1l0.6,1c1.3,2.3-0.2,5.5-3.3,7.1l-43,22.4
C476.6,104.5,474.5,103.9,473.5,102.2L473.5,102.2"
                  />
                </g>
                <g id="Blade-1_1_">
                  <polygon id="Fill-173_9_" className="st1"
                           points="531.2,64.3 535.9,64.3 535.9,52 531.2,52 						"/>
                  <polygon id="Fill-174_9_" className="st2"
                           points="533.5,64.3 535.9,64.3 535.9,52 533.5,52 						"/>
                  <path
                    id="Fill-175_9_"
                    className="st3"
                    d="M536.9,1.6c-0.1-1.9-1.6-3.4-3.5-3.4c-1.8,0-3.4,1.5-3.5,3.4L527.8,50
c-0.2,3.4,1.9,6.4,4.5,6.4l2.4,0c2.6,0,4.6-2.9,4.5-6.4L536.9,1.6"
                  />
                  <path
                    id="Fill-176_9_"
                    className="st1"
                    d="M533.5-1.8v58.2h-1.2c-2.6,0-4.7-2.9-4.5-6.4L530,1.6C530-0.3,531.6-1.8,533.5-1.8
L533.5-1.8"
                  />
                </g>
                <path
                  id="Fill-187_7_"
                  className="st4"
                  d="M539.9,67.7c0,3.5-2.9,6.4-6.4,6.4c-3.5,0-6.4-2.9-6.4-6.4c0-3.5,2.9-6.4,6.4-6.4
C537.1,61.3,539.9,64.1,539.9,67.7"
                />
                <path
                  id="Fill-188_7_"
                  className="st5"
                  d="M537.3,67.7c0,2.1-1.7,3.8-3.8,3.8c-2.1,0-3.8-1.7-3.8-3.8c0-2.1,1.7-3.8,3.8-3.8
C535.6,63.9,537.3,65.6,537.3,67.7"
                />
              </g>
              <g id="Leg_1_">
                <path
                  id="Fill-185_7_"
                  className="st1"
                  d="M540.7,225.4h-14.4l3.5-142.8c0-2,1.7-3.6,3.7-3.6c2,0,3.6,1.6,3.7,3.6L540.7,225.4"
                />
                <path id="Fill-186_7_" className="st3" d="M540.7,225.4h-7.2V79c2,0,3.6,1.6,3.7,3.6L540.7,225.4"/>
              </g>
            </g>
            <g id="Mill-5-l" transform="translate(73.710000, 47.970000)" className="st0">
              <g id="Blades_2_" className="spinning-part spinning-part--5">
                <g id="Blade-3_3_">
                  <polygon id="Fill-173_14_" className="st1"
                           points="351.4,38.8 348.5,43.9 361.9,51.7 364.8,46.5 						"/>
                  <polygon id="Fill-174_14_" className="st2"
                           points="349.9,41.4 348.5,43.9 361.9,51.7 363.4,49.1 						"/>
                  <path
                    id="Fill-175_14_"
                    className="st3"
                    d="M416.2,84.5c2.1,1.1,4.7,0.3,5.9-1.7c1.2-2,0.5-4.6-1.5-5.9l-51.5-32.8
c-3.7-2.3-8.1-2-9.8,0.9l-1.5,2.6c-1.7,2.9,0.3,6.9,4.1,8.9L416.2,84.5"
                  />
                  <path
                    id="Fill-176_14_"
                    className="st1"
                    d="M422,83l-63.5-36.6l0.7-1.3c1.6-2.9,6.1-3.2,9.8-0.9L420.6,77c2,1.3,2.7,3.9,1.5,5.9
L422,83"
                  />
                </g>
                <g id="Blade-2_2_">
                  <polygon id="Fill-173_13_" className="st1"
                           points="344.4,43.9 341.5,38.8 328.1,46.5 331,51.7 						"/>
                  <polygon id="Fill-174_13_" className="st2"
                           points="343,41.4 341.5,38.8 328.1,46.5 329.5,49.1 						"/>
                  <path
                    id="Fill-175_13_"
                    className="st3"
                    d="M272.4,77.2c-2,1.3-2.7,3.9-1.5,5.9c1.2,2,3.8,2.7,5.9,1.6L331,56.5
c3.9-2,5.8-6.1,4.1-8.9l-1.5-2.6c-1.7-2.9-6.1-3.2-9.8-0.9L272.4,77.2"
                  />
                  <path
                    id="Fill-176_13_"
                    className="st1"
                    d="M270.9,83l63.5-36.6l0.7,1.3c1.6,2.9-0.2,6.9-4.1,8.9l-54.1,28.2
c-2.1,1.1-4.7,0.4-5.9-1.6L270.9,83"
                  />
                </g>
                <g id="Blade-1_2_">
                  <polygon id="Fill-173_12_" className="st1"
                           points="343.4,35.3 349.4,35.3 349.4,19.8 343.4,19.8 						"/>
                  <polygon id="Fill-174_12_" className="st2"
                           points="346.4,35.3 349.4,35.3 349.4,19.8 346.4,19.8 						"/>
                  <path
                    id="Fill-175_12_"
                    className="st3"
                    d="M350.7-43.7c-0.1-2.4-2.1-4.3-4.4-4.3c-2.3,0-4.3,1.9-4.4,4.3l-2.7,61
c-0.2,4.3,2.4,8,5.7,8l3,0c3.3,0,5.8-3.7,5.6-8L350.7-43.7"
                  />
                  <path
                    id="Fill-176_12_"
                    className="st1"
                    d="M346.4-48v73.3h-1.5c-3.3,0-5.9-3.7-5.7-8l2.7-61c0.1-2.4,2-4.3,4.4-4.3H346.4"
                  />
                </g>
                <path
                  id="Fill-187_8_"
                  className="st4"
                  d="M354.5,39.5c0,4.5-3.6,8.1-8.1,8.1c-4.5,0-8.1-3.6-8.1-8.1c0-4.5,3.6-8.1,8.1-8.1
C350.9,31.4,354.5,35,354.5,39.5"
                />
                <path
                  id="Fill-188_8_"
                  className="st5"
                  d="M351.2,39.5c0,2.7-2.2,4.8-4.8,4.8c-2.7,0-4.8-2.2-4.8-4.8c0-2.7,2.2-4.8,4.8-4.8
C349.1,34.7,351.2,36.8,351.2,39.5"
                />
              </g>
              <g id="Leg_2_">
                <path
                  id="Fill-185_8_"
                  className="st1"
                  d="M355.5,238h-18.1l4.4-179.8c0.1-2.5,2.1-4.5,4.6-4.5c2.5,0,4.6,2,4.6,4.5L355.5,238"
                />
                <path id="Fill-186_8_" className="st3" d="M355.5,238h-9.1V53.7c2.5,0,4.6,2,4.6,4.5L355.5,238"/>
              </g>
            </g>
            <g id="Mill-4-s" transform="translate(73.710000, 47.970000)" className="st0">
              <g id="Blades_3_" className="spinning-part spinning-part--4">
                <g id="Blade-3_4_">
                  <polygon id="Fill-173_17_" className="st1"
                           points="312.9,117.5 311,120.7 319.3,125.5 321.1,122.3 						"/>
                  <polygon id="Fill-174_17_" className="st2"
                           points="312,119.1 311,120.7 319.3,125.5 320.2,123.9 						"/>
                  <path
                    id="Fill-175_17_"
                    className="st3"
                    d="M352.8,145.7c1.3,0.7,2.9,0.2,3.6-1c0.7-1.2,0.3-2.8-0.9-3.6l-31.7-20.2
c-2.3-1.4-5-1.2-6,0.6l-0.9,1.6c-1,1.8,0.2,4.3,2.5,5.5L352.8,145.7"
                  />
                  <path
                    id="Fill-176_17_"
                    className="st1"
                    d="M356.4,144.8l-39.1-22.6l0.5-0.8c1-1.8,3.8-2,6-0.6l31.7,20.2
C356.7,141.8,357.1,143.5,356.4,144.8L356.4,144.8"
                  />
                </g>
                <g id="Blade-2_3_">
                  <polygon
                    id="Fill-173_16_"
                    className="st1"
                    points="308.6,120.7 306.7,117.5 298.5,122.3 300.3,125.5 						"
                  />
                  <polygon
                    id="Fill-174_16_"
                    className="st2"
                    points="307.7,119.1 306.7,117.5 298.5,122.3 299.4,123.9 						"
                  />
                  <path
                    id="Fill-175_16_"
                    className="st3"
                    d="M264.2,141.2c-1.2,0.8-1.6,2.4-0.9,3.6c0.7,1.2,2.3,1.7,3.6,1l33.4-17.4
c2.4-1.2,3.6-3.7,2.5-5.5l-0.9-1.6c-1-1.8-3.8-2-6-0.5L264.2,141.2"
                  />
                  <path
                    id="Fill-176_16_"
                    className="st1"
                    d="M263.2,144.8l39.1-22.6l0.5,0.8c1,1.8-0.2,4.3-2.5,5.5l-33.3,17.4
C265.6,146.5,264,146.1,263.2,144.8L263.2,144.8"
                  />
                </g>
                <g id="Blade-1_3_">
                  <polygon
                    id="Fill-173_15_"
                    className="st1"
                    points="307.9,115.4 311.6,115.4 311.6,105.8 307.9,105.8 						"
                  />
                  <polygon
                    id="Fill-174_15_"
                    className="st2"
                    points="309.8,115.4 311.6,115.4 311.6,105.8 309.8,105.8 						"
                  />
                  <path
                    id="Fill-175_15_"
                    className="st3"
                    d="M312.4,66.7c-0.1-1.5-1.3-2.6-2.7-2.6c-1.4,0-2.6,1.2-2.7,2.6l-1.6,37.6
c-0.1,2.7,1.5,4.9,3.5,4.9l1.8,0c2,0,3.6-2.3,3.5-4.9L312.4,66.7"
                  />
                  <path
                    id="Fill-176_15_"
                    className="st1"
                    d="M309.8,64.1v45.1h-0.9c-2,0-3.6-2.3-3.5-4.9l1.6-37.6
C307.1,65.2,308.3,64.1,309.8,64.1L309.8,64.1"
                  />
                </g>
                <path
                  id="Fill-187_9_"
                  className="st4"
                  d="M314.8,118c0,2.7-2.2,5-5,5c-2.7,0-5-2.2-5-5c0-2.8,2.2-5,5-5
C312.5,113,314.8,115.2,314.8,118"
                />
                <path
                  id="Fill-188_9_"
                  className="st5"
                  d="M312.7,118c0,1.6-1.3,3-3,3c-1.6,0-3-1.3-3-3c0-1.6,1.3-3,3-3
C311.4,115,312.7,116.3,312.7,118"
                />
              </g>
              <g id="Leg_3_">
                <path
                  id="Fill-185_9_"
                  className="st1"
                  d="M315.4,240.3h-11.2l2.7-110.7c0-1.5,1.3-2.8,2.9-2.8c1.5,0,2.8,1.2,2.9,2.8L315.4,240.3"
                />
                <path id="Fill-186_9_" className="st3" d="M315.4,240.3h-5.6V126.7c1.5,0,2.8,1.2,2.9,2.8L315.4,240.3"/>
              </g>
            </g>
            <g id="Mill-3-s" transform="translate(73.710000, 47.970000)" className="st0">
              <g id="Blades_4_" className="spinning-part spinning-part--3">
                <g id="Blade-3_5_">
                  <polygon id="Fill-173_20_" className="st1"
                           points="160.2,117.8 158.4,121 166.6,125.8 168.5,122.6 						"/>
                  <polygon id="Fill-174_20_" className="st2"
                           points="159.3,119.4 158.4,121 166.6,125.8 167.6,124.2 						"/>
                  <path
                    id="Fill-175_20_"
                    className="st3"
                    d="M200.1,146c1.3,0.7,2.9,0.2,3.6-1c0.7-1.2,0.3-2.8-0.9-3.6l-31.7-20.2
c-2.3-1.4-5-1.2-6,0.6l-0.9,1.6c-1,1.8,0.2,4.3,2.5,5.5L200.1,146"
                  />
                  <path
                    id="Fill-176_20_"
                    className="st1"
                    d="M203.7,145l-39.1-22.6l0.5-0.8c1-1.8,3.8-2,6-0.6l31.7,20.2
C204.1,142.1,204.5,143.7,203.7,145L203.7,145"
                  />
                </g>
                <g id="Blade-2_4_">
                  <polygon id="Fill-173_19_" className="st1"
                           points="155.9,121 154.1,117.8 145.8,122.6 147.6,125.8 						"/>
                  <polygon id="Fill-174_19_" className="st2"
                           points="155,119.4 154.1,117.8 145.8,122.6 146.7,124.2 						"/>
                  <path
                    id="Fill-175_19_"
                    className="st3"
                    d="M111.5,141.5c-1.2,0.8-1.6,2.4-0.9,3.6c0.7,1.2,2.3,1.7,3.6,1l33.4-17.4
c2.4-1.2,3.6-3.7,2.5-5.5l-0.9-1.6c-1-1.8-3.8-2-6-0.5L111.5,141.5"
                  />
                  <path
                    id="Fill-176_19_"
                    className="st1"
                    d="M110.6,145l39.1-22.6l0.5,0.8c1,1.8-0.2,4.3-2.5,5.5l-33.3,17.4
C112.9,146.8,111.3,146.3,110.6,145L110.6,145"
                  />
                </g>
                <g id="Blade-1_4_">
                  <polygon
                    id="Fill-173_18_"
                    className="st1"
                    points="155.3,115.6 158.9,115.6 158.9,106.1 155.3,106.1 						"
                  />
                  <polygon
                    id="Fill-174_18_"
                    className="st2"
                    points="157.1,115.6 158.9,115.6 158.9,106.1 157.1,106.1 						"
                  />
                  <path
                    id="Fill-175_18_"
                    className="st3"
                    d="M159.7,67c-0.1-1.5-1.3-2.6-2.7-2.6c-1.4,0-2.6,1.2-2.7,2.6l-1.6,37.6
c-0.1,2.7,1.5,4.9,3.5,4.9l1.8,0c2,0,3.6-2.3,3.5-4.9L159.7,67"
                  />
                  <path
                    id="Fill-176_18_"
                    className="st1"
                    d="M157.1,64.4v45.1h-0.9c-2,0-3.6-2.3-3.5-4.9l1.6-37.6
C154.4,65.5,155.6,64.4,157.1,64.4L157.1,64.4"
                  />
                </g>
                <path
                  id="Fill-187_10_"
                  className="st4"
                  d="M162.1,118.2c0,2.7-2.2,5-5,5c-2.7,0-5-2.2-5-5c0-2.8,2.2-5,5-5
C159.9,113.3,162.1,115.5,162.1,118.2"
                />
                <path
                  id="Fill-188_10_"
                  className="st5"
                  d="M160.1,118.2c0,1.6-1.3,3-3,3c-1.6,0-3-1.3-3-3c0-1.6,1.3-3,3-3
C158.8,115.3,160.1,116.6,160.1,118.2"
                />
              </g>
              <g id="Leg_4_">
                <path
                  id="Fill-185_10_"
                  className="st1"
                  d="M162.7,240.6h-11.2l2.7-110.7c0-1.5,1.3-2.8,2.9-2.8c1.5,0,2.8,1.2,2.9,2.8L162.7,240.6
"
                />
                <path id="Fill-186_10_" className="st3" d="M162.7,240.6h-5.6V127c1.5,0,2.8,1.2,2.9,2.8L162.7,240.6"/>
              </g>
            </g>
            <g id="Mill-2" transform="translate(73.710000, 47.970000)" className="st0">
              <g id="Blades" className="spinning-part spinning-part--2">
                <g id="Blade-3_2_">
                  <polygon id="Fill-173_8_" className="st1"
                           points="68.8,68.9 66.5,73 77.1,79.2 79.5,75.1 						"/>
                  <polygon id="Fill-174_8_" className="st2" points="67.6,71 66.5,73 77.1,79.2 78.3,77.1 						"/>
                  <path
                    id="Fill-175_8_"
                    className="st3"
                    d="M120.3,105.2c1.7,0.9,3.7,0.3,4.7-1.3c0.9-1.6,0.4-3.7-1.2-4.7L82.9,73.2
c-2.9-1.9-6.5-1.6-7.8,0.7l-1.2,2c-1.3,2.3,0.2,5.5,3.3,7.1L120.3,105.2"
                  />
                  <path
                    id="Fill-176_8_"
                    className="st1"
                    d="M124.9,104L74.5,74.9l0.6-1c1.3-2.3,4.8-2.6,7.8-0.7l40.9,26
C125.4,100.3,125.9,102.3,124.9,104L124.9,104"
                  />
                </g>
                <g id="Blade-2">
                  <polygon id="Fill-173_7_" className="st1"
                           points="63.3,73 60.9,68.9 50.3,75.1 52.6,79.2 						"/>
                  <polygon id="Fill-174_7_" className="st2"
                           points="62.1,71 60.9,68.9 50.3,75.1 51.4,77.1 						"/>
                  <path
                    id="Fill-175_7_"
                    className="st3"
                    d="M6.1,99.4c-1.6,1-2.1,3.1-1.2,4.7c0.9,1.6,3,2.2,4.7,1.3l43-22.4
c3.1-1.6,4.6-4.8,3.3-7.1l-1.2-2c-1.3-2.3-4.9-2.6-7.8-0.7L6.1,99.4"
                  />
                  <path
                    id="Fill-176_7_"
                    className="st1"
                    d="M4.8,104l50.4-29.1l0.6,1c1.3,2.3-0.2,5.5-3.3,7.1l-43,22.4
C7.9,106.3,5.8,105.7,4.8,104L4.8,104"
                  />
                </g>
                <g id="Blade-1">
                  <polygon id="Fill-173_6_" className="st1"
                           points="62.5,66.1 67.2,66.1 67.2,53.8 62.5,53.8 						"/>
                  <polygon id="Fill-174_6_" className="st2"
                           points="64.8,66.1 67.2,66.1 67.2,53.8 64.8,53.8 						"/>
                  <path
                    id="Fill-175_6_"
                    className="st3"
                    d="M68.2,3.4C68.1,1.5,66.6,0,64.7,0c-1.8,0-3.4,1.5-3.5,3.4l-2.1,48.4
c-0.2,3.4,1.9,6.4,4.5,6.4l2.4,0c2.6,0,4.6-2.9,4.5-6.4L68.2,3.4"
                  />
                  <path
                    id="Fill-176_6_"
                    className="st1"
                    d="M64.8,0v58.2h-1.2c-2.6,0-4.7-2.9-4.5-6.4l2.1-48.4C61.3,1.5,62.9,0,64.8,0L64.8,0"
                  />
                </g>
                <path
                  id="Fill-187_6_"
                  className="st4"
                  d="M71.3,69.5c0,3.5-2.9,6.4-6.4,6.4c-3.5,0-6.4-2.9-6.4-6.4c0-3.5,2.9-6.4,6.4-6.4
C68.4,63.1,71.3,65.9,71.3,69.5"
                />
                <path
                  id="Fill-188_6_"
                  className="st5"
                  d="M68.7,69.5c0,2.1-1.7,3.8-3.8,3.8c-2.1,0-3.8-1.7-3.8-3.8c0-2.1,1.7-3.8,3.8-3.8
C66.9,65.6,68.7,67.4,68.7,69.5"
                />
              </g>
              <g id="Leg">
                <path
                  id="Fill-185_6_"
                  className="st1"
                  d="M72,227.2H57.6l3.5-142.8c0-2,1.7-3.6,3.7-3.6c2,0,3.6,1.6,3.7,3.6L72,227.2"
                />
                <path id="Fill-186_6_" className="st3" d="M72,227.2h-7.2V80.8c2,0,3.6,1.6,3.7,3.6L72,227.2"/>
              </g>
            </g>
            <g id="Mill-1-s" transform="translate(73.710000, 47.970000)" className="st0">
              <g id="Blades_6_" className="spinning-part spinning-part--1">
                <g id="Blade-3_7_">
                  <polygon id="Fill-173_26_" className="st1"
                           points="-21.9,112.8 -23.7,116 -15.5,120.8 -13.6,117.6 						"/>
                  <polygon id="Fill-174_26_" className="st2"
                           points="-22.8,114.4 -23.7,116 -15.5,120.8 -14.5,119.2 						"/>
                  <path
                    id="Fill-175_26_"
                    className="st3"
                    d="M18,141c1.3,0.7,2.9,0.2,3.6-1c0.7-1.2,0.3-2.8-0.9-3.6L-11,116.1
c-2.3-1.4-5-1.2-6,0.6l-0.9,1.6c-1,1.8,0.2,4.3,2.5,5.5L18,141"
                  />
                  <path
                    id="Fill-176_26_"
                    className="st1"
                    d="M21.6,140l-39.1-22.6l0.5-0.8c1-1.8,3.8-2,6-0.6l31.7,20.2
C21.9,137.1,22.3,138.7,21.6,140L21.6,140"
                  />
                </g>
                <g id="Blade-2_6_">
                  <polygon id="Fill-173_25_" className="st1"
                           points="-26.2,116 -28,112.8 -36.3,117.6 -34.5,120.8 						"/>
                  <polygon id="Fill-174_25_" className="st2"
                           points="-27.1,114.4 -28,112.8 -36.3,117.6 -35.4,119.2 						"/>
                  <path
                    id="Fill-175_25_"
                    className="st3"
                    d="M-70.6,136.5c-1.2,0.8-1.6,2.4-0.9,3.6c0.7,1.2,2.3,1.7,3.6,1l33.4-17.4
c2.4-1.2,3.6-3.7,2.5-5.5l-0.9-1.6c-1-1.8-3.8-2-6-0.5L-70.6,136.5"
                  />
                  <path
                    id="Fill-176_25_"
                    className="st1"
                    d="M-71.5,140l39.1-22.6l0.5,0.8c1,1.8-0.2,4.3-2.5,5.5l-33.3,17.4
C-69.2,141.8-70.8,141.3-71.5,140L-71.5,140"
                  />
                </g>
                <g id="Blade-1_6_">
                  <polygon
                    id="Fill-173_24_"
                    className="st1"
                    points="-26.8,110.6 -23.2,110.6 -23.2,101.1 -26.8,101.1 						"
                  />
                  <polygon id="Fill-174_24_" className="st2"
                           points="-25,110.6 -23.2,110.6 -23.2,101.1 -25,101.1 						"/>
                  <path
                    id="Fill-175_24_"
                    className="st3"
                    d="M-22.4,62c-0.1-1.5-1.3-2.6-2.7-2.6c-1.4,0-2.6,1.2-2.7,2.6l-1.6,37.6
c-0.1,2.7,1.5,4.9,3.5,4.9l1.8,0c2,0,3.6-2.3,3.5-4.9L-22.4,62"
                  />
                  <path
                    id="Fill-176_24_"
                    className="st1"
                    d="M-25,59.4v45.1h-0.9c-2,0-3.6-2.3-3.5-4.9l1.6-37.6C-27.7,60.5-26.5,59.4-25,59.4
L-25,59.4"
                  />
                </g>
                <path
                  id="Fill-187_12_"
                  className="st4"
                  d="M-20,113.3c0,2.7-2.2,5-5,5c-2.7,0-5-2.2-5-5c0-2.8,2.2-5,5-5C-22.2,108.3-20,110.5-20,113.3"
                />
                <path
                  id="Fill-188_12_"
                  className="st5"
                  d="M-22,113.3c0,1.6-1.3,3-3,3c-1.6,0-3-1.3-3-3c0-1.6,1.3-3,3-3C-23.4,110.3-22,111.6-22,113.3"
                />
              </g>
              <g id="Leg_6_">
                <path
                  id="Fill-185_12_"
                  className="st1"
                  d="M-19.4,235.6h-11.2l2.7-110.7c0-1.5,1.3-2.8,2.9-2.8c1.5,0,2.8,1.2,2.9,2.8L-19.4,235.6"
                />
                <path id="Fill-186_12_" className="st3" d="M-19.4,235.6H-25V122c1.5,0,2.8,1.2,2.9,2.8L-19.4,235.6"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
