import { ServiceReport, ServicesResponse } from './api';

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function parseDate(date: Date): { dd: string; mm: string; yyyy: string } {
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = date.getFullYear();
  return { dd: dd, mm: mm, yyyy: yyyy.toString() };
}

export function getMonthLabel(date: Date) {
  return monthNames[date.getMonth()] + '  ' + date.getFullYear();
}

export function getFormattedDate(date: Date) {
  const parsedDate = parseDate(date);
  return parsedDate.dd + '.' + parsedDate.mm + '.' + parsedDate.yyyy;
}

export function getDateKey(date: Date) {
  const parsedDate = parseDate(date);
  return parsedDate.yyyy + '-' + parsedDate.mm + '-' + parsedDate.dd;
}

export function getMonthKey(date: Date) {
  const parsedDate = parseDate(date);
  return parsedDate.mm + '.' + parsedDate.yyyy;
}

export function getDateStringForAPICall(date: Date) {
  const parsedDate = parseDate(date);
  return parsedDate.yyyy + parsedDate.mm + parsedDate.dd;
}

export function getColor(color: string) {
  switch (color) {
    case 'red':
      return getComputedStyle(document.body).getPropertyValue('--redColor');
    case 'purple':
      return getComputedStyle(document.body).getPropertyValue('--purpleColor');
    case 'blue':
      return getComputedStyle(document.body).getPropertyValue('--blueColor');
    default:
      return getComputedStyle(document.body).getPropertyValue('--primaryColorLight');
  }
}

export function getMapColors(color: string) {
  switch (color) {
    case 'red':
      return {
        top: getComputedStyle(document.body).getPropertyValue('--mapRedLight'),
        middle: getComputedStyle(document.body).getPropertyValue('--mapRedDark'),
      };
    case 'purple':
      return {
        top: getComputedStyle(document.body).getPropertyValue('--mapPurpleLight'),
        middle: getComputedStyle(document.body).getPropertyValue('--mapPurpleDark'),
      };
    case 'blue':
      return {
        top: getComputedStyle(document.body).getPropertyValue('--mapBlueLight'),
        middle: getComputedStyle(document.body).getPropertyValue('--mapBlueDark'),
      };
    default:
      return {
        top: getComputedStyle(document.body).getPropertyValue('--mapGrayLight'),
        middle: getComputedStyle(document.body).getPropertyValue('--mapGrayDark'),
      };
  }
}

export function formatTimeFrame(timeframe: string): string {
  switch (timeframe) {
    case 'dayAhead':
      return 'Day Ahead';
    case 'weekAhead':
      return 'Week';
    case 'yearAhead':
      return 'Year';
    case 'intraDay':
      return 'Intra Day';
    default:
      return '';
  }
}

export function getServiceFullName(label: string) {
  switch (label.toLowerCase()) {
    case 'csa':
      return 'Coordinated security analysis';
    case 'cc cwe':
      return 'Coordinated capacity calculation';
    case 'cc ibwt':
      return 'Coordinated capacity calculation';
    case 'opc':
    case 'opc/opi':
      return 'Outage planning coordination';
    case 'sta':
      return 'Short term adequacy';
    default:
      return '';
  }
}

export function getInitDay() {
  const initDate = new Date();
  initDate.setDate(initDate.getDate() - 1);
  initDate.setHours(initDate.getHours() - 6);
  return initDate;
}

export const initNavBar = [
  { label: 'opc/opi', status: '' },
  { label: 'sta', status: '' },
  { label: 'csa', status: '' },
  { label: 'cc cwe', status: '' },
  { label: 'cc ibwt', status: '' },
];

export const emptyServicesResponse: ServicesResponse = {};

export const sortServices = (a: ServiceReport, b: ServiceReport) => {
  const serviceLables = initNavBar.map((el) => el.label);
  const aLabelIndex = serviceLables.indexOf(a.label.toLowerCase());
  const bLabelIndex = serviceLables.indexOf(b.label.toLowerCase());
  return aLabelIndex - bLabelIndex;
};

export const defaultServices: {
  [key: string]: ServiceReport;
} = {
  csa: {
    date: getDateKey(getInitDay()),
    label: 'csa',
    kpis: [],
    coloring: {
      names: {
        inner: 'intraDay',
        outer: 'dayAhead',
      },
      reports: [
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
      ],
      worstOfSameKpis: null,
    },
  },
  'cc ibwt': {
    date: getDateKey(getInitDay()),
    label: 'cc ibwt',
    kpis: [],
    coloring: {
      names: {
        outer: 'intraDay',
      },
      reports: [
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
      ],
      worstOfSameKpis: null,
    },
  },
  'cc cwe': {
    date: getDateKey(getInitDay()),
    label: 'cc cwe',
    kpis: [],
    coloring: {
      names: {
        outer: 'dayAhead',
      },
      reports: [
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
      ],
      worstOfSameKpis: null,
    },
  },
  sta: {
    date: getDateKey(getInitDay()),
    label: 'sta',
    kpis: [],
    coloring: {
      names: {
        outer: 'dayAhead',
      },
      reports: [
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
      ],
      worstOfSameKpis: null,
    },
  },
  'opc/opi': {
    date: getDateKey(getInitDay()),
    label: 'opc/opi',
    kpis: [],
    coloring: {
      names: {
        outer: 'weekAhead',
      },
      reports: [
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
        {
          date: '',
          inner: 'gray',
          outer: 'gray',
        },
      ],
      worstOfSameKpis: null,
    },
  },
};

