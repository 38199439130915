import React from 'react';
import '../styles/Login.css';

const logo = require('../assets/LogoTSCNET.svg');

export function Login() {
  return (
    <div>
      <img alt="logo" className="logo" src={String(logo)} />
    </div>
  );
}
