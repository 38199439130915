import React, { useEffect, useState } from 'react';
import '../styles/History.css';
import DayPicker, { DayModifiers } from 'react-day-picker';
import { getColor, getInitDay, getMonthKey, getMonthLabel } from '../utils/utils';
import 'react-day-picker/lib/style.css';
import { CalendarServiceResponse } from '../utils/api';
import { SmallCalendar } from './SVGs/SmallCalendar';
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io';

interface HistoryProps {
  changeDateCallback: (newDate: Date | undefined) => void;
  currentDay: Date | undefined;
  calendarColoring: CalendarServiceResponse;
  monthSwitched?: boolean
}

interface CalendarProps {
  changeDateCallback: (newDate: Date | undefined) => void;
  currentDay: Date | undefined;
  calendarColoring: CalendarServiceResponse;
  selectedMonth: Date | undefined;
}

// make an array with last 12 months
const allMonths = () => {
  const initDay = getInitDay();
  const months: Date[] = [getInitDay()];

  for (let i = 1; i < 12; i++) {
    months[i] = new Date(initDay.setMonth(initDay.getMonth() - 1));
  }
  return months;
};

// make an array with first days of last 12 months
const firstDaysAllMonths = () => {
  return allMonths().map((day) => new Date(day.getFullYear(), day.getMonth(), 1));
};

// get colorings for all days of last 12 months
const calendarColoringProcessing = (calendarColoring: CalendarServiceResponse) => {
  const redDates: Date[] = [];
  const purpleDates: Date[] = [];
  const blueDates: Date[] = [];

  allMonths().map((month) => {
    const monthKey = getMonthKey(month);
    if (calendarColoring !== undefined && calendarColoring[monthKey] !== undefined) {
      calendarColoring[monthKey].map((color, index) => {
        const newDate = new Date(month.getFullYear(), month.getMonth(), index + 1);
        switch (color) {
          case 'red':
            redDates.push(newDate);
            break;
          case 'purple':
            purpleDates.push(newDate);
            break;
          case 'blue':
            blueDates.push(newDate);
            break;
        }
      });
    }
    return null;
  });

  return { redDates: redDates, purpleDates: purpleDates, blueDates: blueDates };
};

// interactive calendar
export function Calendar(props: CalendarProps) {
  const { changeDateCallback, currentDay, calendarColoring, selectedMonth } = props;
  const parsedCalendarColoring = calendarColoringProcessing(calendarColoring);

  const handleDayClick = (day: Date, modifiers: DayModifiers) => {
    if (modifiers.disabled) {
      return;
    }
    if (!modifiers.selected) {
      handleChangeDate(day);
    }
  };

  const handleChangeDate = (day: Date) => {
    changeDateCallback(day);
  };

  // assign days to modifiers for days coloring
  const modifiers = {
    all: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6, 7] },
    red: parsedCalendarColoring.redDates, //[new Date(2020, 8, 8), new Date(2020, 8, 7)],
    purple: parsedCalendarColoring.purpleDates, //new Date(2020, 8, 11),
    blue: parsedCalendarColoring.blueDates, //new Date(2020, 8, 10),
  };

  // create styles for days coloring
  const modifiersStyles = {
    all: {
      backgroundColor: getComputedStyle(document.body).getPropertyValue('--primaryColorDark'),
    },
    red: {
      color: getComputedStyle(document.body).getPropertyValue('--primaryColorBackground'),
      backgroundColor: getColor('red'),
    },
    purple: {
      color: getComputedStyle(document.body).getPropertyValue('--primaryColorBackground'),
      backgroundColor: getColor('purple'),
    },
    blue: {
      color: getComputedStyle(document.body).getPropertyValue('--primaryColorBackground'),
      backgroundColor: getColor('blue'),
    },
  };

  return (
    <div className="calendar-container">
      <DayPicker
        selectedDays={currentDay}
        onDayClick={handleDayClick}
        disabledDays={[{ after: getInitDay() }]}
        modifiers={modifiers}
        modifiersStyles={modifiersStyles}
        month={selectedMonth}
      />
    </div>
  );
}

// history view
export function History(props: HistoryProps) {
  const { calendarColoring, changeDateCallback, currentDay, monthSwitched } = props;
  const [selectedMonth, setSelectedMonth] = useState(currentDay ?? getInitDay());

  // change month by click on small colored calendar
  const changeMonth = (newMonth: Date) => {
    setSelectedMonth(newMonth);
  };

  // change month by click on arrow back
  const handleChangeMonthBack = () => {
    const newDate = new Date(selectedMonth);
    newDate.setMonth(newDate.getMonth() - 1);
    setSelectedMonth(new Date(newDate));
  };

  // change month by click on arrow forward
  const handleChangeMonthForward = () => {
    const newDate = new Date(selectedMonth);
    newDate.setMonth(newDate.getMonth() + 1);
    setSelectedMonth(new Date(newDate));
  };

  // change month by parent initiation - part of guided tour
  useEffect(() => {
    if (monthSwitched) {
      handleChangeMonthBack()
    }
  }, [monthSwitched]);

  return (
    <div className="history-container">

      <div className="switch-date__container">
        <div onClick={handleChangeMonthBack} className="switch-date__icon__container">
          <IoIosArrowDropleftCircle className="switch-date__icon" />
        </div>
        <div className="switch-date__label__container">{getMonthLabel(selectedMonth ?? getInitDay())}</div>
        <div onClick={handleChangeMonthForward} className="switch-date__icon__container">
          <IoIosArrowDroprightCircle className="switch-date__icon" />
        </div>
      </div>

      <Calendar
        changeDateCallback={changeDateCallback}
        currentDay={currentDay}
        calendarColoring={calendarColoring}
        selectedMonth={selectedMonth}
      />

      <div className="calendar-divider" />
      <div className={'colored-calendar-container'}>
        {firstDaysAllMonths().map((day, index) => (
          <div
            key={'small-calendar-' + index}
            className="small-calendar-container"
            onClick={() => {
              changeMonth(day);
            }}
          >
            <SmallCalendar monthColoring={calendarColoring[getMonthKey(day)] ?? []} firstDayOfMonth={day} />
          </div>
        ))}
      </div>
    </div>
  );
}
